import { useState, lazy, Suspense } from 'react';
import { Routes, Route, useNavigate, Navigate } from 'react-router-dom';
import { useAuth } from '@/contexts/AuthContext';
import { Sidebar } from '@/components/member/Sidebar';

// Lazy load components
const ShareDialog = lazy(() => import('@/components/member/ShareDialog'));
const UserProfile = lazy(() => import('@/components/member/UserProfile'));
const ProjectsList = lazy(() => import('@/components/member/ProjectsList'));
const Tracking = lazy(() => import('@/pages/member/Tracking'));
const ContactSupport = lazy(() => import('@/components/member/ContactSupport'));

const LoadingSpinner = () => (
  <div className="flex items-center justify-center h-full">
    <div className="text-white/60">Carregando...</div>
  </div>
);

const MemberArea = () => {
  const [isShareOpen, setIsShareOpen] = useState(false);
  const { logout } = useAuth();
  const navigate = useNavigate();

  const handleLogout = () => {
    logout();
    navigate('/');
  };

  return (
    <div className="min-h-screen" style={{ marginTop: 'calc(24px + 6rem)', paddingBottom: '1rem' }}>
      <div className="container mx-auto px-2 max-w-[98%] h-[calc(100vh-9rem)]">
        <div className="flex h-full bg-black/20 rounded-lg border border-white/10">
          {/* Sidebar */}
          <Sidebar onLogout={handleLogout} />

          {/* Main Content */}
          <div className="flex-1 p-4 overflow-y-auto">
            <Suspense fallback={<LoadingSpinner />}>
              <Routes>
                <Route index element={<UserProfile />} />
                <Route path="projects" element={<ProjectsList />} />
                <Route path="tracking" element={<Tracking />} />
                <Route path="*" element={<Navigate to="/member" replace />} />
              </Routes>
            </Suspense>
          </div>

          {/* Share Dialog */}
          <Suspense fallback={null}>
            {isShareOpen && <ShareDialog open={isShareOpen} onOpenChange={setIsShareOpen} />}
          </Suspense>
        </div>
      </div>
    </div>
  );
};

export default MemberArea;