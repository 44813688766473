import { Routes, Route } from 'react-router-dom';
import { AuthProvider } from './contexts/AuthContext';
import { CustomToaster } from '@/components/ui/toaster';
import Navbar from './components/Navbar';
import Home from './pages/Home';
import About from './pages/About';
import Manifesto from './pages/Manifesto';
import FAQ from '@/pages/FAQ';
import News from './pages/News';
import Contact from './pages/Contact';
import SubmitProject from './pages/SubmitProject';
import MemberArea from './pages/MemberArea';
import Terms from './pages/Terms';
import Privacy from './pages/Privacy';
import DataPolicy from './pages/DataPolicy';
import Footer from './components/Footer';
import WhatsAppButton from './components/WhatsAppButton';
import { useScrollTop } from './hooks/useScrollTop';
import { useGoogleAnalytics } from './hooks/useGoogleAnalytics';
import ConstructionBackground from './components/animations/ConstructionBackground';
import AdminDashboard from './components/AdminDashboard';
import { Navigate } from 'react-router-dom';
import { useAuth } from './contexts/AuthContext';

interface ProtectedRouteProps {
  children: React.ReactNode;
  requiredRole?: 'user' | 'admin';
}

function ProtectedRoute({ children, requiredRole }: ProtectedRouteProps) {
  const { user, isAuthenticated } = useAuth();
  
  // Not authenticated at all
  if (!isAuthenticated || !user) {
    return <Navigate to="/" replace />;
  }

  // Check for required role
  if (requiredRole && user.role !== requiredRole) {
    // If user is admin, allow access to user routes
    if (user.role === 'admin' && requiredRole === 'user') {
      return <>{children}</>;
    }
    return <Navigate to="/" replace />;
  }
  
  return <>{children}</>;
}

function AppContent() {
  useGoogleAnalytics();
  useScrollTop();

  return (
    <div className="min-h-screen flex flex-col">
      <ConstructionBackground />
      <Navbar />
      <main className="flex-1">
        <Routes>
          {/* Public Routes */}
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/manifesto" element={<Manifesto />} />
          <Route path="/news" element={<News />} />
          <Route path="/faq" element={<FAQ />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/submit-project" element={<SubmitProject />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/data-policy" element={<DataPolicy />} />
          
          {/* Member Routes */}
          <Route
            path="/member"
            element={
              <ProtectedRoute requiredRole="user">
                <MemberArea />
              </ProtectedRoute>
            }
          />
          <Route
            path="/member/*"
            element={
              <ProtectedRoute requiredRole="user">
                <MemberArea />
              </ProtectedRoute>
            }
          />

          {/* Admin Routes */}
          <Route
            path="/admin"
            element={
              <ProtectedRoute requiredRole="admin">
                <AdminDashboard />
              </ProtectedRoute>
            }
          />
          <Route
            path="/admin/*"
            element={
              <ProtectedRoute requiredRole="admin">
                <AdminDashboard />
              </ProtectedRoute>
            }
          />
        </Routes>
      </main>
      <Footer />
      <WhatsAppButton />
      <CustomToaster 
        toastOptions={{
          style: {
            background: 'rgba(0, 0, 0, 0.8)',
            color: '#fff',
            backdropFilter: 'blur(10px)',
            border: '1px solid rgba(255, 255, 255, 0.1)'
          },
          position: 'top-right',
          theme: 'dark',
          richColors: true,
          closeButton: true,
        }}
      />
    </div>
  );
}

function App() {
  return (
    <AuthProvider>
      <AppContent />
    </AuthProvider>
  );
}

export default App;