import { Card } from '@/components/ui/card';
import { Button } from '@/components/ui/button';
import { Mail, Phone, MapPin, ArrowRight } from 'lucide-react';
import { useContentStore } from '@/stores/contentStore';
import { motion } from 'framer-motion';
import { useEffect } from 'react';
import { DEFAULT_IMAGES } from '@/constants/defaultImages';

const Contact = () => {
  const { contact } = useContentStore();

  useEffect(() => {
    // Hide WhatsApp floating button on mount
    const whatsappButton = document.querySelector('[data-whatsapp-float]');
    if (whatsappButton) {
      whatsappButton.classList.add('hidden');
    }

    // Show WhatsApp floating button on unmount
    return () => {
      if (whatsappButton) {
        whatsappButton.classList.remove('hidden');
      }
    };
  }, []);

  if (!contact) return null;

  return (
    <div className="min-h-screen">
      {/* Hero Section */}
      <section className="h-[85vh] md:h-[70vh] md:-mt-5 flex items-center justify-center relative overflow-hidden">
        <div 
          className="absolute inset-0 bg-cover bg-center bg-no-repeat"
          style={{
            backgroundImage: `url(${contact.heroImage || contact.bannerImage || DEFAULT_IMAGES.contact})`,
            backgroundPosition: 'center 30%'
          }}
        >
          <div className="absolute inset-0 bg-gradient-to-b from-black/80 via-black/50 to-black" />
        </div>

        <div className="relative z-10 text-center max-w-6xl mx-auto px-4 mt-32 md:mt-16">
          <motion.h1 
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            className="text-[2rem] md:text-4xl font-bold text-white mb-3 max-w-6xl mx-auto"
          >
            {contact?.title || "Olá, Partner 👋"}
          </motion.h1>
          <motion.p
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.1 }}
            className="text-xl text-white/90 max-w-6xl mx-auto mt-6"
          >
            {contact?.subtitle || "Como podemos te ajudar?"}
          </motion.p>
          <p className="text-white/70 mt-4 max-w-6xl mx-auto">
            {contact.description}
          </p>
        </div>
      </section>

      {/* Contact Info Section */}
      <div className="max-w-7xl mx-auto px-8 -mt-32 relative z-10">
        <div className="grid md:grid-cols-3 gap-8">
          <div className="bg-black/40 backdrop-blur-xl border border-white/10 rounded-2xl p-8 shadow-[0_8px_32px_rgba(255,255,255,0.1)] flex flex-col items-center text-center">
            <div className="text-4xl mb-4">📧</div>
            <h3 className="text-xl font-semibold mb-2">E-mail</h3>
            <p className="text-gray-300">{contact.email}</p>
          </div>

          <div className="bg-black/40 backdrop-blur-xl border border-white/10 rounded-2xl p-8 shadow-[0_8px_32px_rgba(255,255,255,0.1)] flex flex-col items-center text-center">
            <div className="text-4xl mb-4">📱</div>
            <h3 className="text-xl font-semibold mb-2">Telefone</h3>
            <p className="text-gray-300">{contact.phone}</p>
          </div>

          <div className="bg-black/40 backdrop-blur-xl border border-white/10 rounded-2xl p-8 shadow-[0_8px_32px_rgba(255,255,255,0.1)] flex flex-col items-center text-center">
            <div className="text-4xl mb-4">📍</div>
            <h3 className="text-xl font-semibold mb-2">Endereço</h3>
            <p className="text-gray-300">{contact.address}</p>
          </div>
        </div>

        <br/> <br/> <br/>

        <motion.div 
          className="relative group"
          animate={{
            y: [0, -10, 0],
          }}
          transition={{
            duration: 2,
            repeat: Infinity,
            ease: "easeInOut"
          }}
        >
          {/* Glow effect behind the box */}
          <div className="absolute -inset-2 bg-green-500/20 rounded-3xl blur-xl group-hover:bg-green-500/30 transition-colors duration-500" />
          
          {/* Main content box */}
          <div className="relative bg-gradient-to-br from-green-500/30 to-green-600/40 backdrop-blur-xl border border-green-400/50 rounded-2xl p-6 sm:p-10 flex flex-col items-center text-center max-w-2xl mx-auto hover:scale-105 transition-all duration-500 shadow-[0_0_30px_rgba(34,197,94,0.3)] hover:shadow-[0_0_50px_rgba(34,197,94,0.5)]">
            <h2 className="text-2xl font-bold mb-4">Vamos conversar?</h2>
            <p className="text-lg text-white/90 mb-6">
              Estamos prontos para ajudar você a transformar seu projeto em realidade.
            </p>
            <Button
              asChild
              className="bg-green-500 hover:bg-green-400 text-white px-6 sm:px-10 py-4 sm:py-6 rounded-xl text-base sm:text-lg font-semibold
                shadow-[0_0_30px_rgba(34,197,94,0.3)] hover:shadow-[0_0_50px_rgba(34,197,94,0.5)]
                transition-all duration-500 relative overflow-hidden group/btn w-full sm:w-auto"
            >
              <a href="https://wa.me/5531993984114" target="_blank" rel="noopener noreferrer" className="flex items-center gap-2 sm:gap-3 justify-center">
                <span className="relative z-10 text-sm sm:text-base">Iniciar conversa no WhatsApp</span>
                <ArrowRight className="w-5 h-5 sm:w-6 sm:h-6 relative z-10 group-hover/btn:translate-x-1 transition-transform" />
                <div className="absolute inset-0 bg-gradient-to-r from-green-400 to-green-500 opacity-0 group-hover/btn:opacity-100 transition-opacity duration-500" />
              </a>
            </Button>
          </div>
        </motion.div>
      </div>

      {/* Add spacing before footer */}
      <div className="h-24" />
    </div>
  );
};

export default Contact;