import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Card } from '@/components/ui/card';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Textarea } from '@/components/ui/textarea';
import { Checkbox } from '@/components/ui/checkbox';
import { Label } from '@/components/ui/label';
import { motion } from 'framer-motion';
import { Upload, FileText, X } from 'lucide-react';
import { useAuth } from '@/contexts/AuthContext';
import { submitProject, getProjects } from '@/lib/project-service';
import { formatPhoneNumber } from '@/lib/utils';
import { useContentStore } from '@/stores/contentStore';
import { SubmissionSuccessDialog } from '@/components/SubmissionSuccessDialog';
import { RegisterDialog } from '@/components/RegisterDialog';
import { toast } from 'sonner';

const formatFileSize = (bytes: number): string => {
  if (bytes === 0) return '0 Bytes';
  const k = 1024;
  const sizes = ['Bytes', 'KB', 'MB', 'GB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(2))} ${sizes[i]}`;
};

const SubmitProject = () => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const { updateProjects } = useContentStore();
  const [files, setFiles] = useState<File[]>([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showRegister, setShowRegister] = useState(false);
  const [submittedEmail, setSubmittedEmail] = useState('');
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    description: '',
    deliverables: [] as string[]
  });

  const deliverableOptions = [
    'Resumo Orçamentário',
    'Orçamento Detalhado',
    'Análise de Materiais',
    'Curva ABC',
    'Composições de Custo'
  ];

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const newFiles = Array.from(e.target.files);
      const updatedFiles = [...files, ...newFiles];
      
      if (updatedFiles.length > 15) {
        toast.error('Máximo de 15 arquivos permitidos');
        return;
      }

      // Check for duplicate file names
      const fileNames = new Set();
      const uniqueFiles = updatedFiles.filter(file => {
        if (fileNames.has(file.name)) {
          return false;
        }
        fileNames.add(file.name);
        return true;
      });

      setFiles(uniqueFiles);
      
      // Reset the input value to allow selecting the same file again
      e.target.value = '';
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!formData.name || !formData.email || !formData.phone || !formData.description) {
      toast.error('Por favor, preencha todos os campos obrigatórios');
      return;
    }

    // Email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(formData.email)) {
      toast.error('Digite um email válido');
      return;
    }

    // Phone validation
    const phoneRegex = /^\(\d{2}\) \d{5}-\d{4}$/;
    if (!phoneRegex.test(formData.phone)) {
      toast.error('Digite um número de telefone válido');
      return;
    }

    if (isSubmitting) {
      return;
    }

    setIsSubmitting(true);

    try {
      const projectData = {
        ...formData,
        files,
        userId: user?.id,
        type: 'project'
      };

      // Submit the project
      const response = await submitProject(projectData);

      if (response.success) {
        // Store the submitted email for the success dialog
        setSubmittedEmail(formData.email);

        // If user is logged in, update their projects list
        if (user?.id) {
          const updatedProjects = await getProjects(user.id);
          updateProjects(updatedProjects);
        }

        // Reset form
        setFormData({
          name: '',
          email: '',
          phone: '',
          description: '',
          deliverables: []
        });
        setFiles([]);

        // Show success dialog
        setShowSuccess(true);
      }
    } catch (error) {
      console.error('Error submitting project:', error);
      toast.error(
        error instanceof Error 
          ? error.message 
          : 'Erro ao enviar projeto. Por favor, tente novamente.'
      );
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleRedirect = () => {
    setShowSuccess(false);
    if (user) {
      navigate('/member/submissions');
    } else {
      // Store email in sessionStorage for registration
      sessionStorage.setItem('pendingRegistrationEmail', submittedEmail);
      setShowRegister(true);
    }
  };

  const removeFile = (fileToRemove: File) => {
    setFiles(files.filter(file => file !== fileToRemove));
  };

  return (
    <>
      <SubmissionSuccessDialog 
        open={showSuccess} 
        onOpenChange={setShowSuccess}
        isLoggedIn={!!user}
        onSkip={handleRedirect}
        submittedEmail={submittedEmail}
      />
      
      <RegisterDialog
        open={showRegister}
        onOpenChange={setShowRegister}
      />
      
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className="container mx-auto px-4 py-8 max-w-4xl"
      >
        <div className="min-h-screen">
          <div className="container mx-auto px-4">
            <div className="pt-28 mt-20 pb-16">
              <motion.h1 
                className="text-5xl font-bold mb-12 text-center bg-clip-text text-transparent bg-gradient-to-r from-white to-gray-300"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8 }}
              >
                Envie seu Projeto
              </motion.h1>

              <Card className="p-8 bg-black/40 backdrop-blur-xl border-white/10">
                <form onSubmit={handleSubmit} className="space-y-6">
                  <div>
                    <Label>Digite seu Nome</Label>
                    <Input
                      value={formData.name}
                      onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                      className="bg-white/5 border-white/10"
                      placeholder="Nome completo"
                      required
                    />
                  </div>

                  <div>
                    <Label>Digite seu Email</Label>
                    <Input
                      type="email"
                      value={formData.email}
                      onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                      className="bg-white/5 border-white/10"
                      placeholder="exemplo@email.com"
                      required
                    />
                  </div>

                  <div>
                    <Label>Digite seu Telefone</Label>
                    <Input
                      value={formData.phone}
                      onChange={(e) => {
                        const formatted = formatPhoneNumber(e.target.value);
                        setFormData({ ...formData, phone: formatted });
                      }}
                      className="bg-white/5 border-white/10"
                      placeholder="(00) 00000-0000"
                      required
                    />
                  </div>

                  <div>
                    <Label>Descrição do Projeto</Label>
                    <Textarea
                      value={formData.description}
                      onChange={(e) => setFormData({ ...formData, description: e.target.value })}
                      className="bg-white/5 border-white/10 min-h-[120px]"
                      placeholder="Descreva seu projeto..."
                      required
                    />
                  </div>

                  <div className="space-y-4">
                    <Label>O que você gostaria de receber?</Label>
                    <div className="grid sm:grid-cols-2 gap-4">
                      {deliverableOptions.map((option) => (
                        <label
                          key={option}
                          className="flex items-center space-x-2 cursor-pointer"
                        >
                          <Checkbox
                            checked={formData.deliverables.includes(option)}
                            onCheckedChange={(checked) => {
                              if (checked) {
                                setFormData({
                                  ...formData,
                                  deliverables: [...formData.deliverables, option]
                                });
                              } else {
                                setFormData({
                                  ...formData,
                                  deliverables: formData.deliverables.filter(
                                    (item) => item !== option
                                  )
                                });
                              }
                            }}
                          />
                          <span>{option}</span>
                        </label>
                      ))}
                    </div>
                  </div>

                  <div className="space-y-4">
                    <Label>Anexar Arquivos</Label>
                    <div className="grid gap-4">
                      <div
                        className={`
                          border-2 border-dashed rounded-lg p-8
                          ${files.length === 0 ? 'border-white/10' : 'border-white/20'}
                          hover:border-white/30 transition-colors
                          bg-white/5 relative
                        `}
                      >
                        <input
                          type="file"
                          onChange={handleFileChange}
                          className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
                          multiple
                        />
                        <div className="text-center">
                          <Upload className="mx-auto h-8 w-8 mb-2 text-white/50" />
                          <p className="text-sm text-white/70">
                            Arraste arquivos ou clique para selecionar
                          </p>
                          <p className="text-xs text-white/50 mt-1">
                            Máximo de 15 arquivos
                          </p>
                        </div>
                      </div>

                      {files.length > 0 && (
                        <div className="space-y-2">
                          {files.map((file, index) => (
                            <div
                              key={index}
                              className="flex items-center justify-between p-3 bg-white/5 rounded-lg"
                            >
                              <div className="flex items-center space-x-3">
                                <FileText className="h-5 w-5 text-white/70" />
                                <div>
                                  <p className="text-sm font-medium">{file.name}</p>
                                  <p className="text-xs text-white/50">
                                    {formatFileSize(file.size)}
                                  </p>
                                </div>
                              </div>
                              <Button
                                type="button"
                                variant="ghost"
                                size="icon"
                                onClick={() => removeFile(file)}
                              >
                                <X className="h-4 w-4" />
                              </Button>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>

                  <Button
                    type="submit"
                    disabled={isSubmitting}
                    className="w-full"
                  >
                    {isSubmitting ? 'Enviando...' : 'Enviar Projeto'}
                  </Button>
                </form>
              </Card>
            </div>
          </div>
        </div>
      </motion.div>
    </>
  );
};

export default SubmitProject;