import { useState, useEffect } from 'react';
import { Dialog, DialogContent } from '@/components/ui/dialog';
import { CheckCircle2 } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { useNavigate } from 'react-router-dom';

interface SubmissionSuccessDialogProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  isLoggedIn: boolean;
  onSkip: () => void;
  submittedEmail: string;
}

export function SubmissionSuccessDialog({ 
  open, 
  onOpenChange, 
  isLoggedIn, 
  onSkip,
  submittedEmail 
}: SubmissionSuccessDialogProps) {
  const [countdown, setCountdown] = useState(isLoggedIn ? 2 : 5);
  const navigate = useNavigate();

  useEffect(() => {
    if (!open) {
      setCountdown(isLoggedIn ? 2 : 5);
      return;
    }

    const timer = setInterval(() => {
      setCountdown((prev) => {
        if (prev <= 1) {
          clearInterval(timer);
          onSkip();
          return 0;
        }
        return prev - 1;
      });
    }, 1000);

    return () => clearInterval(timer);
  }, [open, isLoggedIn, onSkip]);

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent 
        className="max-w-lg bg-[#0a1930]/80 border-white/10 backdrop-blur-xl shadow-xl 
          before:absolute before:inset-0 before:-z-10 before:rounded-lg
          before:bg-gradient-radial before:from-white/10 before:to-transparent before:blur-xl
          after:absolute after:inset-0 after:-z-10 after:rounded-lg
          after:bg-gradient-radial after:from-white/5 after:to-transparent
          font-poppins"
      >
        <div className="flex flex-col items-center justify-between py-8 px-6">
          <div className="flex flex-col items-center text-center space-y-6">
            <CheckCircle2 className="h-16 w-16 text-green-500" />
            <h2 className="text-lg font-semibold text-white">
              Projeto enviado com sucesso!
            </h2>
            {isLoggedIn ? (
              <p className="text-sm text-white/80">
                Aguarde, você será redirecionado para a lista de submissões.
              </p>
            ) : (
              <div className="text-sm text-white/80 space-y-4">
                <p>
                  Para acompanhar seus projetos, <span className="font-semibold text-green-500">crie uma conta</span>!
                </p>
                <p>
                  Você será redirecionado para a página de registro com seu email já preenchido.
                </p>
              </div>
            )}
          </div>

          <div className="flex flex-col items-center space-y-4 mt-8">
            <div className="text-sm font-medium text-white/90">
              Redirecionando em {countdown} segundos...
            </div>
            <Button 
              variant="outline" 
              onClick={onSkip}
              className="bg-white/5 border-white/10 hover:bg-white/10 text-white text-sm"
            >
              Pular espera
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}
