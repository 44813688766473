import axios from 'axios';

// Determine environment and API URL
const isDevelopment = window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1';
const API_URL = isDevelopment ? '' : 'https://www.visiopartners.com.br';

console.log('Environment Setup:', {
  isDevelopment,
  API_URL,
  hostname: window.location.hostname,
  port: window.location.port
});

// Create axios instance with base configuration
const api = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json'
  },
  withCredentials: true
});

// Add request interceptor for debugging and auth
api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    
    // Log request details
    console.log('API Request:', {
      url: config.url,
      baseURL: config.baseURL,
      method: config.method,
      headers: config.headers,
      data: config.data
    });
    
    return config;
  },
  (error) => {
    console.error('Request Error:', error);
    return Promise.reject(error);
  }
);

// Add response interceptor for better error handling
api.interceptors.response.use(
  (response) => {
    console.log('API Response:', {
      url: response.config.url,
      status: response.status,
      data: response.data
    });
    return response;
  },
  (error) => {
    console.error('API Error:', {
      url: error.config?.url,
      method: error.config?.method,
      status: error.response?.status,
      data: error.response?.data,
      message: error.message
    });
    return Promise.reject(error);
  }
);

// In-memory storage for local development
const localUsers = new Map();

// Initialize admin user
localUsers.set('admin@visiopartners.com.br', {
  uid: 'admin-uid',
  id: 'admin-id',
  name: 'Admin User',
  email: 'admin@visiopartners.com.br',
  password: 'VisioAdmin@2024',
  role: 'admin',
  createdAt: new Date().toISOString()
});

// Login function
export const login = async (email: string, password: string) => {
  try {
    console.log('Attempting login for:', email);
    
    const response = await api.post('/api/login', { email, password });
    console.log('Login response:', response.data);
    
    if (!response.data) {
      throw new Error('Resposta vazia do servidor');
    }
    
    // Check for token in different possible locations
    const token = response.data.token || response.data.accessToken || response.data.access_token;
    if (!token) {
      throw new Error('Token não encontrado na resposta');
    }
    
    // Handle different user object structures
    const userData = response.data.user || response.data.userData || response.data;
    if (!userData || typeof userData !== 'object') {
      throw new Error('Dados do usuário inválidos');
    }
    
    // Ensure user object has required fields
    const user = {
      ...userData,
      id: userData.id || userData.sub || userData._id || userData.userId,
      role: userData.role || 'user',
      email: email // Ensure email is set
    };
    
    return {
      token,
      user
    };
  } catch (error: any) {
    console.error('Login error:', {
      message: error.message,
      response: error.response?.data
    });
    
    if (error.response?.status === 401) {
      throw new Error('Email ou senha incorretos');
    }
    
    throw new Error(error.message || 'Erro ao fazer login');
  }
};

// Register function
export const register = async (userData: {
  name: string;
  email: string;
  password: string;
  company?: string;
}) => {
  try {
    console.log('Attempting registration for:', userData.email);
    
    const response = await api.post('/api/register', userData);
    console.log('Registration response:', response.data);
    
    if (!response.data) {
      throw new Error('Resposta vazia do servidor');
    }
    
    // Check for token in different possible locations
    const token = response.data.token || response.data.accessToken || response.data.access_token;
    if (!token) {
      throw new Error('Token não encontrado na resposta');
    }
    
    // Handle different user object structures
    const userDataResponse = response.data.user || response.data.userData || response.data;
    if (!userDataResponse || typeof userDataResponse !== 'object') {
      throw new Error('Dados do usuário inválidos');
    }
    
    // Ensure user object has required fields
    const user = {
      ...userDataResponse,
      id: userDataResponse.id || userDataResponse.sub || userDataResponse._id || userDataResponse.userId,
      role: userDataResponse.role || 'user',
      email: userData.email // Ensure email is set
    };
    
    return {
      token,
      user
    };
  } catch (error: any) {
    console.error('Registration error:', {
      message: error.message,
      response: error.response?.data
    });
    
    if (error.response?.status === 409 || 
        (error.response?.status === 400 && error.response?.data?.error === 'Email already registered')) {
      throw new Error('Este email já está cadastrado');
    }
    
    throw new Error(error.message || 'Erro ao criar conta');
  }
};

// Submit project function
export const submitProject = async (data: any) => {
  try {
    const response = await api.post('/api/projects', data);
    return response.data;
  } catch (error: any) {
    console.error('Project submission error:', error);
    throw new Error('Erro ao enviar projeto. Por favor, tente novamente.');
  }
};

// Get projects function
export const getProjects = async () => {
  try {
    const response = await api.get('/api/projects');
    return response.data;
  } catch (error: any) {
    console.error('Get projects error:', error);
    throw new Error('Erro ao buscar projetos. Por favor, tente novamente.');
  }
};

export default api;