import { useState, useEffect } from 'react';
import { Card } from '@/components/ui/card';
import { Button } from '@/components/ui/button';
import { Dialog, DialogContent, DialogHeader, DialogTitle } from '@/components/ui/dialog';
import { X } from 'lucide-react';
import { motion } from 'framer-motion';
import { useContentStore } from '@/stores/contentStore';
import { DEFAULT_IMAGES } from '@/constants/defaultImages';
import { formatDatePtBR } from '@/utils/dateFormat';

interface NewsImageState {
  [key: string]: string;
}

const News = () => {
  const [selectedNews, setSelectedNews] = useState<any>(null);
  const { news } = useContentStore();
  const [newsImages, setNewsImages] = useState<NewsImageState>({});

  // Helper function to validate image URL
  const validateImage = (url: string): Promise<string> => {
    return new Promise((resolve) => {
      const img = new Image();
      img.onload = () => resolve(url);
      img.onerror = () => resolve(DEFAULT_IMAGES.news);
      img.src = url;
    });
  };

  // Load and validate all news images
  useEffect(() => {
    const loadImages = async () => {
      const imagePromises = news.map(async (item) => {
        const validatedUrl = await validateImage(item.image || item.imageUrl);
        return [item.id, validatedUrl];
      });

      const validatedImages = await Promise.all(imagePromises);
      const imagesMap = Object.fromEntries(validatedImages);
      setNewsImages(imagesMap);
    };

    loadImages();
  }, [news]);

  return (
    <div className="pt-40 min-h-screen">
      <div className="max-w-6xl mx-auto px-4 pb-32">
        <h1 className="text-5xl font-bold mb-12 text-center bg-clip-text text-transparent bg-gradient-to-r from-white to-gray-300">
          Notícias
        </h1>

        <div className="grid md:grid-cols-3 gap-8">
          {news.map((item, index) => (
            <motion.div
              key={item.id || index}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: index * 0.1 }}
              className="relative group h-full"
            >
              {/* Glow effect */}
              <div className="absolute -inset-1 bg-white/20 rounded-2xl blur-xl group-hover:bg-white/30 transition-colors duration-500" />
              
              {/* Glass card */}
              <Card 
                className="relative h-full flex flex-col cursor-pointer overflow-hidden
                  bg-white/10 backdrop-blur-lg border border-white/5 rounded-xl
                  transform hover:scale-105 transition-all duration-300
                  shadow-[0_8px_32px_rgba(255,255,255,0.1)]
                  group-hover:shadow-[0_8px_32px_rgba(255,255,255,0.2)]
                  before:absolute before:inset-0 before:rounded-xl before:border-t before:border-l before:border-white/20 before:-z-[1] before:translate-y-0.5
                  after:absolute after:inset-0 after:rounded-xl after:border-b after:border-r after:border-black/20 after:-z-[1] after:-translate-y-0.5"
                onClick={() => setSelectedNews(item)}
              >
                <div className="relative h-48 overflow-hidden rounded-t-xl">
                  <img
                    src={newsImages[item.id] || DEFAULT_IMAGES.news}
                    alt={item.title}
                    className="absolute inset-0 w-full h-full object-cover transition-transform duration-300 group-hover:scale-105"
                    loading="lazy"
                  />
                  <div className="absolute inset-0 bg-gradient-to-t from-[#0a192f]/90 to-transparent" />
                </div>
                <div className="content p-6 flex flex-col flex-1">
                  <div className="flex items-center justify-between mb-4">
                    <span className="text-sm text-white/80">{formatDatePtBR(item.date)}</span>
                    <span className="text-sm px-3 py-1 rounded-full bg-white/10 backdrop-blur-md text-white/90">
                      {item.category}
                    </span>
                  </div>
                  <h3 className="text-xl font-semibold mb-2 line-clamp-2 text-white">{item.title}</h3>
                  <p className="text-white/90 mb-4 line-clamp-3 h-[4.5rem]">{item.description}</p>
                  <Button variant="ghost" className="group-hover:text-white transition-colors mt-auto hover:bg-white/10">
                    Leia mais
                  </Button>
                </div>
              </Card>
            </motion.div>
          ))}
        </div>
      </div>

      <Dialog open={!!selectedNews} onOpenChange={() => setSelectedNews(null)}>
        <DialogContent className="max-w-3xl bg-black/40 border-white/20 backdrop-blur-2xl">
          {selectedNews && (
            <>
              <DialogHeader>
                <div className="flex justify-between items-start">
                  <div>
                    <DialogTitle className="text-2xl font-bold mb-2">
                      {selectedNews.title}
                    </DialogTitle>
                    <div className="flex items-center gap-4 text-sm text-white/80">
                      <span>{formatDatePtBR(selectedNews.date)}</span>
                      <span>•</span>
                      <span>{selectedNews.category}</span>
                    </div>
                  </div>
                  <button
                    onClick={() => setSelectedNews(null)}
                    className="absolute top-4 right-4 text-white/50 hover:text-white transition-colors"
                  >
                    <X className="h-6 w-6" />
                  </button>
                </div>
              </DialogHeader>

              <div className="h-[calc(85vh-5rem)] overflow-y-auto custom-scrollbar">
                <div className="px-8 py-6">
                  <div className="max-w-2xl mx-auto">
                    <img
                      src={newsImages[selectedNews.id] || DEFAULT_IMAGES.news}
                      alt={selectedNews.title}
                      className="w-full h-[300px] object-cover rounded-xl mb-8"
                      loading="lazy"
                    />
                    
                    <div 
                      className="prose prose-invert max-w-none"
                      dangerouslySetInnerHTML={{ __html: selectedNews.content }}
                    />
                  </div>
                </div>
              </div>
            </>
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default News;