import { useState, useEffect } from 'react';
import { Card } from '@/components/ui/card';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Textarea } from '@/components/ui/textarea';
import { Label } from '@/components/ui/label';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { Dialog, DialogContent, DialogHeader, DialogTitle } from '@/components/ui/dialog';
import { PlusCircle, Trash2, Edit2, Save, X, FileText, Download, Search } from 'lucide-react';
import { toast } from 'sonner';
import { useContentStore } from '@/stores/contentStore';
import { getProjects, downloadFile } from '@/lib/project-service';
import type { Project, ProjectFile } from '@/types/project';
import type { NewsItem } from '@/types/content';
import { formatFileSize } from '@/lib/utils';
import { ContentEditor } from './admin/ContentEditor';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select';

const AdminDashboard = () => {
  const { news, updateNews, projects, updateProjects } = useContentStore();
  const [currentNews, setCurrentNews] = useState<NewsItem>({
    id: '',
    title: '',
    subtitle: '',
    description: '',
    content: '',
    author: 'Equipe Visio',
    date: new Date().toISOString(),
    image: '',
    imageUrl: '',
    category: '',
    readTime: '5 min'
  });
  const [editingNews, setEditingNews] = useState<NewsItem | null>(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  
  // Submissions filtering state
  const [searchTerm, setSearchTerm] = useState('');
  const [dateFilter, setDateFilter] = useState('all');
  const [filteredProjects, setFilteredProjects] = useState<Project[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(5);

  const contentTabs = [
    { id: 'home', label: 'Página Inicial' },
    { id: 'about', label: 'A Visio' },
    { id: 'manifesto', label: 'Manifesto' },
    { id: 'contact', label: 'Contato' },
    { id: 'faq', label: 'FAQ' },
  ];

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const projectsData = await getProjects();
        updateProjects(projectsData);
      } catch (error) {
        toast.error('Erro ao carregar projetos');
      }
    };

    fetchProjects();
  }, []);

  // Filter projects whenever search term, date filter, or projects change
  useEffect(() => {
    let result = [...(projects || [])];

    // Sort by date (newest first)
    result.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());

    // Apply search filter
    if (searchTerm) {
      const term = searchTerm.toLowerCase();
      result = result.filter(project => 
        project.submissionDetails.name.toLowerCase().includes(term) ||
        project.submissionDetails.email.toLowerCase().includes(term) ||
        project.submissionDetails.description.toLowerCase().includes(term) ||
        project.submissionDetails.phone.toLowerCase().includes(term) ||
        project.submissionDetails.deliverables.some(d => d.toLowerCase().includes(term))
      );
    }

    // Apply date filter
    if (dateFilter !== 'all') {
      const now = new Date();
      const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
      const projectDate = (date: string) => new Date(date);

      switch (dateFilter) {
        case 'today':
          result = result.filter(project => 
            projectDate(project.date).toDateString() === today.toDateString()
          );
          break;
        case 'week':
          const weekAgo = new Date(today.getTime() - 7 * 24 * 60 * 60 * 1000);
          result = result.filter(project => 
            projectDate(project.date) >= weekAgo
          );
          break;
        case 'month':
          const monthAgo = new Date(today.getFullYear(), today.getMonth() - 1, today.getDate());
          result = result.filter(project => 
            projectDate(project.date) >= monthAgo
          );
          break;
      }
    }

    setFilteredProjects(result);
  }, [projects, searchTerm, dateFilter]);

  const handleDownload = async (file: ProjectFile) => {
    try {
      if (!file.key || !file.name) {
        throw new Error('Informações do arquivo inválidas');
      }
      await downloadFile(file.key, file.name);
    } catch (error) {
      console.error('Error downloading file:', error);
      toast.error('Erro ao baixar arquivo');
    }
  };

  const handleAddNews = () => {
    setEditingNews(null);
    setCurrentNews({
      id: '',
      title: '',
      subtitle: '',
      description: '',
      content: '',
      author: 'Equipe Visio',
      date: new Date().toISOString(),
      image: '',
      imageUrl: '',
      category: '',
      readTime: '5 min'
    });
    setIsDialogOpen(true);
  };

  const handleEditNews = (item: NewsItem) => {
    setEditingNews(item);
    setCurrentNews(item);
    setIsDialogOpen(true);
  };

  const handleDeleteNews = (id: string) => {
    const updatedNews = news.filter(item => item.id !== id);
    updateNews(updatedNews);
    toast.success('Notícia excluída com sucesso');
  };

  const handleSaveNews = () => {
    if (editingNews) {
      const updatedNews = news.map(item =>
        item.id === editingNews.id ? { ...currentNews } : item
      );
      updateNews(updatedNews);
      toast.success('Notícia atualizada com sucesso');
    } else {
      const newItem: NewsItem = {
        ...currentNews,
        id: Date.now().toString(),
      };
      updateNews([...news, newItem]);
      toast.success('Notícia adicionada com sucesso');
    }
    setIsDialogOpen(false);
  };

  return (
    <div className="min-h-screen pt-32 pb-24">
      <div className="container mx-auto px-4 space-y-8">
        <div className="flex justify-between items-center">
          <h1 className="text-2xl font-bold">Painel Administrativo</h1>
        </div>

        <Card className="bg-black/20 border-white/10">
          <div className="p-6">
            <Tabs defaultValue="content" className="space-y-6">
              <TabsList className="bg-black/20">
                <TabsTrigger 
                  value="content"
                  className="data-[state=active]:bg-white/10"
                >
                  Conteúdo
                </TabsTrigger>
                <TabsTrigger 
                  value="news"
                  className="data-[state=active]:bg-white/10"
                >
                  Notícias
                </TabsTrigger>
                <TabsTrigger 
                  value="submissions"
                  className="data-[state=active]:bg-white/10"
                >
                  Submissões
                </TabsTrigger>
                <TabsTrigger 
                  value="faq"
                  className="data-[state=active]:bg-white/10"
                >
                  FAQ
                </TabsTrigger>
              </TabsList>

              {/* Content Tab */}
              <TabsContent value="content" className="mt-6">
                <Tabs defaultValue="home" className="space-y-6">
                  <TabsList className="bg-black/20">
                    {contentTabs.map(tab => (
                      <TabsTrigger 
                        key={tab.id} 
                        value={tab.id}
                        className="data-[state=active]:bg-white/10"
                      >
                        {tab.label}
                      </TabsTrigger>
                    ))}
                  </TabsList>

                  {contentTabs.map(tab => (
                    <TabsContent key={tab.id} value={tab.id} className="mt-6">
                      <ContentEditor 
                        section={tab.id as 'home' | 'about' | 'manifesto' | 'contact' | 'faq'} 
                        title={tab.label}
                      />
                    </TabsContent>
                  ))}
                </Tabs>
              </TabsContent>

              {/* News Tab */}
              <TabsContent value="news" className="mt-6">
                <div className="flex justify-between items-center mb-6">
                  <h2 className="text-xl font-semibold">Notícias</h2>
                  <Button onClick={handleAddNews} className="hover:bg-white/20">
                    <PlusCircle className="h-4 w-4 mr-2" />
                    Adicionar Notícia
                  </Button>
                </div>

                <div className="space-y-4">
                  {news?.map((item) => (
                    <div key={item.id} className="flex items-start justify-between p-4 bg-black/20 rounded-lg">
                      <div className="flex-1 mr-4">
                        <h3 className="font-semibold mb-1">{item.title}</h3>
                        <p className="text-sm text-white/60">{item.description}</p>
                        <div className="flex items-center mt-2 space-x-4">
                          <span className="text-sm text-white/60">{item.date}</span>
                          <span className="text-sm px-2 py-1 bg-white/10 rounded-full">
                            {item.category}
                          </span>
                        </div>
                      </div>
                      <div className="flex space-x-2">
                        <Button
                          variant="ghost"
                          size="icon"
                          onClick={() => handleEditNews(item)}
                          className="hover:bg-white/10"
                        >
                          <Edit2 className="h-4 w-4" />
                        </Button>
                        <Button
                          variant="ghost"
                          size="icon"
                          onClick={() => handleDeleteNews(item.id)}
                          className="hover:bg-white/10"
                        >
                          <Trash2 className="h-4 w-4" />
                        </Button>
                      </div>
                    </div>
                  ))}
                </div>
              </TabsContent>

              {/* Submissions Tab */}
              <TabsContent value="submissions" className="mt-6">
                <div className="space-y-6">
                  <div className="flex flex-col space-y-4">
                    <h2 className="text-xl font-semibold">Submissões de Projetos</h2>
                    
                    {/* Filters */}
                    <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
                      <div>
                        <Label>Buscar</Label>
                        <div className="relative">
                          <Search className="absolute left-2 top-2.5 h-4 w-4 text-white/40" />
                          <Input
                            placeholder="Buscar por nome, email..."
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            className="pl-8 bg-white/5 border-white/10"
                          />
                        </div>
                      </div>

                      <div>
                        <Label>Período</Label>
                        <Select value={dateFilter} onValueChange={setDateFilter}>
                          <SelectTrigger className="bg-white/5 border-white/10">
                            <SelectValue placeholder="Filtrar por data" />
                          </SelectTrigger>
                          <SelectContent>
                            <SelectItem value="all">Todas as datas</SelectItem>
                            <SelectItem value="today">Hoje</SelectItem>
                            <SelectItem value="week">Última semana</SelectItem>
                            <SelectItem value="month">Último mês</SelectItem>
                          </SelectContent>
                        </Select>
                      </div>

                      <div>
                        <Label>Itens por página</Label>
                        <Select 
                          value={pageSize.toString()} 
                          onValueChange={(value) => {
                            setPageSize(Number(value));
                            setCurrentPage(1);
                          }}
                        >
                          <SelectTrigger className="bg-white/5 border-white/10">
                            <SelectValue placeholder="Itens por página" />
                          </SelectTrigger>
                          <SelectContent>
                            <SelectItem value="3">3 itens</SelectItem>
                            <SelectItem value="5">5 itens</SelectItem>
                            <SelectItem value="10">10 itens</SelectItem>
                          </SelectContent>
                        </Select>
                      </div>

                      <div className="flex items-end">
                        <Button 
                          variant="outline" 
                          className="w-full"
                          onClick={() => {
                            setSearchTerm('');
                            setDateFilter('all');
                            setCurrentPage(1);
                          }}
                        >
                          Limpar Filtros
                        </Button>
                      </div>
                    </div>
                  </div>

                  {/* Projects List */}
                  <div className="space-y-4">
                    {filteredProjects.length === 0 ? (
                      <Card className="p-8 bg-black/20 text-center">
                        <p className="text-white/60">Nenhuma submissão encontrada</p>
                      </Card>
                    ) : (
                      <>
                        {/* Pagination Info */}
                        <div className="text-sm text-white/60 mb-4">
                          Mostrando {Math.min((currentPage - 1) * pageSize + 1, filteredProjects.length)} - {Math.min(currentPage * pageSize, filteredProjects.length)} de {filteredProjects.length} submissões
                        </div>

                        {/* Projects */}
                        {filteredProjects
                          .slice((currentPage - 1) * pageSize, currentPage * pageSize)
                          .map((project) => (
                            <Card key={project.id} className="p-4 bg-black/20">
                              <div className="grid gap-4">
                                <div className="flex justify-between items-start">
                                  <div className="space-y-1">
                                    <h3 className="font-semibold text-lg">{project.submissionDetails.name}</h3>
                                    <div className="flex items-center gap-4 text-sm text-white/60">
                                      <span>{project.submissionDetails.email}</span>
                                      <span>{project.submissionDetails.phone}</span>
                                    </div>
                                    <p className="text-sm mt-2">{project.submissionDetails.description}</p>
                                  </div>
                                  <div className="text-right">
                                    <span className="text-sm text-white/60">
                                      {new Date(project.date).toLocaleDateString('pt-BR', {
                                        year: 'numeric',
                                        month: 'long',
                                        day: 'numeric'
                                      })}
                                    </span>
                                  </div>
                                </div>

                                {/* Deliverables */}
                                <div className="mt-2">
                                  <h4 className="text-sm font-medium mb-2">Itens Solicitados:</h4>
                                  <div className="flex flex-wrap gap-2">
                                    {project.submissionDetails.deliverables.map((item, index) => (
                                      <span
                                        key={index}
                                        className="text-xs px-2 py-1 bg-white/10 rounded-full"
                                      >
                                        {item}
                                      </span>
                                    ))}
                                  </div>
                                </div>

                                {/* Files */}
                                <div className="mt-2">
                                  <h4 className="text-sm font-medium mb-2">Arquivos:</h4>
                                  <div className="grid gap-2">
                                    {project.files.map((file, index) => (
                                      <div
                                        key={index}
                                        className="flex items-center justify-between p-2 bg-black/20 rounded-lg"
                                      >
                                        <div className="flex items-center gap-2">
                                          <FileText className="h-4 w-4 text-white/60" />
                                          <span className="text-sm">{file.name}</span>
                                          <span className="text-xs text-white/40">
                                            ({formatFileSize(parseInt(file.size))})
                                          </span>
                                        </div>
                                        <Button
                                          variant="ghost"
                                          size="sm"
                                          className="text-xs hover:bg-white/10"
                                          onClick={() => handleDownload(file)}
                                        >
                                          <Download className="h-4 w-4 mr-1" />
                                          Baixar
                                        </Button>
                                      </div>
                                    ))}
                                  </div>
                                </div>
                              </div>
                            </Card>
                          ))}

                        {/* Pagination Controls */}
                        <div className="flex justify-between items-center mt-6">
                          <Button
                            variant="outline"
                            onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
                            disabled={currentPage === 1}
                            className="hover:bg-white/10"
                          >
                            Anterior
                          </Button>
                          <div className="flex gap-2">
                            {Array.from(
                              { length: Math.ceil(filteredProjects.length / pageSize) },
                              (_, i) => i + 1
                            ).map((page) => (
                              <Button
                                key={page}
                                variant={currentPage === page ? "default" : "outline"}
                                onClick={() => setCurrentPage(page)}
                                className={currentPage === page ? "bg-white/20" : "hover:bg-white/10"}
                              >
                                {page}
                              </Button>
                            ))}
                          </div>
                          <Button
                            variant="outline"
                            onClick={() => 
                              setCurrentPage(prev => 
                                Math.min(prev + 1, Math.ceil(filteredProjects.length / pageSize))
                              )
                            }
                            disabled={currentPage >= Math.ceil(filteredProjects.length / pageSize)}
                            className="hover:bg-white/10"
                          >
                            Próxima
                          </Button>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </TabsContent>

              {/* FAQ Tab */}
              <TabsContent value="faq" className="mt-6">
                <ContentEditor section="faq" title="Editar FAQ" />
              </TabsContent>
            </Tabs>
          </div>
        </Card>

        {/* News Dialog */}
        <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
          <DialogContent className="max-w-2xl max-h-[85vh] w-[90vw] overflow-y-auto bg-black/90 backdrop-blur-xl border-white/10">
            <DialogHeader>
              <DialogTitle>
                {editingNews ? 'Editar Notícia' : 'Nova Notícia'}
              </DialogTitle>
            </DialogHeader>
            
            <div className="space-y-4 pr-4">
              <div>
                <Label>Título</Label>
                <Input
                  value={currentNews.title}
                  onChange={(e) => setCurrentNews({ ...currentNews, title: e.target.value })}
                  className="bg-white/5 border-white/10"
                  placeholder="Título da notícia"
                />
              </div>

              <div>
                <Label>Subtítulo</Label>
                <Input
                  value={currentNews.subtitle}
                  onChange={(e) => setCurrentNews({ ...currentNews, subtitle: e.target.value })}
                  className="bg-white/5 border-white/10"
                  placeholder="Subtítulo da notícia"
                />
              </div>

              <div>
                <Label>Descrição</Label>
                <Textarea
                  value={currentNews.description}
                  onChange={(e) => setCurrentNews({ ...currentNews, description: e.target.value })}
                  className="bg-white/5 border-white/10"
                  placeholder="Descrição da notícia"
                  rows={4}
                />
              </div>

              <div>
                <Label>Conteúdo</Label>
                <Textarea
                  value={currentNews.content}
                  onChange={(e) => setCurrentNews({ ...currentNews, content: e.target.value })}
                  className="bg-white/5 border-white/10"
                  placeholder="Conteúdo da notícia"
                  rows={8}
                />
              </div>

              <div>
                <Label>Autor</Label>
                <Input
                  value={currentNews.author}
                  onChange={(e) => setCurrentNews({ ...currentNews, author: e.target.value })}
                  className="bg-white/5 border-white/10"
                  placeholder="Autor da notícia"
                />
              </div>

              <div>
                <Label>Data</Label>
                <Input
                  type="date"
                  value={currentNews.date}
                  onChange={(e) => setCurrentNews({ ...currentNews, date: e.target.value })}
                  className="bg-white/5 border-white/10"
                />
              </div>

              <div>
                <Label>Imagem</Label>
                <Input
                  value={currentNews.image}
                  onChange={(e) => setCurrentNews({ ...currentNews, image: e.target.value })}
                  className="bg-white/5 border-white/10"
                  placeholder="URL da imagem"
                />
              </div>

              <div>
                <Label>URL da Imagem</Label>
                <Input
                  value={currentNews.imageUrl}
                  onChange={(e) => setCurrentNews({ ...currentNews, imageUrl: e.target.value })}
                  className="bg-white/5 border-white/10"
                  placeholder="URL da imagem"
                />
              </div>

              <div>
                <Label>Categoria</Label>
                <Input
                  value={currentNews.category}
                  onChange={(e) => setCurrentNews({ ...currentNews, category: e.target.value })}
                  className="bg-white/5 border-white/10"
                  placeholder="Ex: Eventos, Atualizações, etc."
                />
              </div>

              <div>
                <Label>Tempo de Leitura</Label>
                <Input
                  value={currentNews.readTime}
                  onChange={(e) => setCurrentNews({ ...currentNews, readTime: e.target.value })}
                  className="bg-white/5 border-white/10"
                  placeholder="Tempo de leitura"
                />
              </div>

              <div className="flex justify-end space-x-2">
                <Button variant="ghost" onClick={() => setIsDialogOpen(false)}>
                  Cancelar
                </Button>
                <Button onClick={handleSaveNews} className="hover:bg-white/20">
                  <Save className="h-4 w-4 mr-2" />
                  Salvar
                </Button>
              </div>
            </div>
          </DialogContent>
        </Dialog>
      </div>
    </div>
  );
};

export default AdminDashboard;