import { openDB } from 'idb';

export async function initDB() {
  return openDB('visio-content', 2, {  // Increment version number to trigger upgrade
    upgrade(db) {
      // Create content store if it doesn't exist
      if (!db.objectStoreNames.contains('content')) {
        db.createObjectStore('content');
      }
      
      // Create projects store if it doesn't exist
      if (!db.objectStoreNames.contains('projects')) {
        const projectsStore = db.createObjectStore('projects', { keyPath: 'id' });
        projectsStore.createIndex('userId', 'userId', { unique: false });
        projectsStore.createIndex('email', 'email', { unique: false });
      }
    },
  });
}

export { openDB };
