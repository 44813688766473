import { Button } from '@/components/ui/button';
import { Card } from '@/components/ui/card';
import { ArrowRight, Building2, BarChart3, LineChart } from 'lucide-react';
import { Link } from 'react-router-dom';
import { useContentStore } from '@/stores/contentStore';
import { ScrollArrow } from '@/components/ScrollArrow';
import { motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import { cn } from '@/lib/utils';
import { getPageImage } from '@/utils/imageHelpers';

const iconMap = {
  Building2,
  BarChart3,
  LineChart
};

const DEFAULT_IMAGES = {
  home: '/assets/home.jpg'
};

const Home = () => {
  const { home } = useContentStore();
  const [imageLoaded, setImageLoaded] = useState(false);
  const [currentImage, setCurrentImage] = useState(DEFAULT_IMAGES.home);

  useEffect(() => {
    const loadImage = async () => {
      const imageUrl = await getPageImage('home', home?.heroImage);
      setCurrentImage(imageUrl);
      setImageLoaded(true);
    };

    loadImage();
  }, [home?.heroImage]);

  if (!home) return null;

  return (
    <div className="min-h-screen">
      {/* Hero Section */}
      <section className="min-h-[90vh] flex items-center justify-center relative overflow-hidden">
        <div 
          className="absolute inset-0 bg-cover bg-center bg-no-repeat"
          style={{
            backgroundImage: `url(${currentImage})`,
            backgroundPosition: 'center 30%',
          }}
        >  
          <div 
            className="absolute inset-0 bg-gradient-to-b from-black/80 via-black/50 to-black"
          />
        </div>

        <div className="relative z-10 text-center max-w-6xl mx-auto px-4 mt-32 md:mt-16">
          <h1 
            className="text-[2rem] md:text-[2.75rem] leading-tight font-bold text-white mb-6"
          >
            {home.mainTitle}
          </h1>
          <p 
            className="text-xl text-white/90 max-w-4xl mx-auto mb-12"
          >
            {home.subtitle}
          </p>
          <div 
            className="inline-block relative"
          >
            {/* Glowing background effect */}
            <div 
              className="absolute -inset-8 bg-white/10 rounded-full blur-3xl opacity-75 
                animate-pulse transition-opacity duration-500"
            />
            
            <motion.div
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              animate={{ 
                y: [0, -8, 0],
                transition: { 
                  duration: 2,
                  repeat: Infinity,
                  ease: "easeInOut" 
                }
              }}
              className="relative"
            >
              <Button
                size="lg"
                className="relative bg-white/90 backdrop-blur-sm
                  text-black hover:bg-white transition-all duration-300
                  shadow-[0_0_40px_rgba(255,255,255,0.4)]
                  hover:shadow-[0_0_60px_rgba(255,255,255,0.6)]
                  text-xl py-8 px-12 rounded-[2rem] font-semibold
                  border border-white/40
                  overflow-hidden"
              >
                <Link to="/submit-project" className="flex items-center gap-4 relative z-10">
                  Envie seu Projeto <ArrowRight className="w-6 h-6" strokeWidth={2.5} />
                </Link>
              </Button>
            </motion.div>
          </div>
        </div>

        <ScrollArrow />
      </section>

      {/* Stats Section */}
      <section className="min-h-[95vh] py-24 relative overflow-hidden bg-black flex items-center justify-center">
        <div 
          className="absolute inset-0 bg-[radial-gradient(circle_at_bottom_left,_var(--tw-gradient-stops))] from-white/[0.03] to-transparent"
          style={{ transform: 'translate3d(0, 0, 0)' }}
        />
        <div className="max-w-6xl mx-auto px-4">
          <motion.h2 
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            className="text-[2.5rem] md:text-[3.5rem] leading-tight font-bold text-white mb-16 text-center"
          >
            Nossos Números
          </motion.h2>
          
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8 md:gap-12">
            <div className="relative group">
              {/* Glow effect */}
              <div className="absolute -inset-1 bg-white/20 rounded-2xl blur-xl group-hover:bg-white/30 transition-colors duration-500" />
              
              {/* Glass card */}
              <div className="relative p-8 rounded-xl bg-white/10 backdrop-blur-lg
                transform hover:scale-105 transition-all duration-300
                shadow-[0_8px_32px_rgba(255,255,255,0.1)]
                group-hover:shadow-[0_8px_32px_rgba(255,255,255,0.2)]
                text-center
                before:absolute before:inset-0 before:rounded-xl before:border-t before:border-l before:border-white/20 before:-z-[1] before:translate-y-0.5
                after:absolute after:inset-0 after:rounded-xl after:border-b after:border-r after:border-black/20 after:-z-[1] after:-translate-y-0.5
                border border-white/5">
                <div className="text-4xl font-black text-white mb-2">248</div>
                <div className="text-lg text-white/90">Projetos Orçados</div>
              </div>
            </div>
            
            <div className="relative group">
              {/* Glow effect */}
              <div className="absolute -inset-1 bg-white/20 rounded-2xl blur-xl group-hover:bg-white/30 transition-colors duration-500" />
              
              {/* Glass card */}
              <div className="relative p-8 rounded-xl bg-white/10 backdrop-blur-lg
                transform hover:scale-105 transition-all duration-300
                shadow-[0_8px_32px_rgba(255,255,255,0.1)]
                group-hover:shadow-[0_8px_32px_rgba(255,255,255,0.2)]
                text-center
                before:absolute before:inset-0 before:rounded-xl before:border-t before:border-l before:border-white/20 before:-z-[1] before:translate-y-0.5
                after:absolute after:inset-0 after:rounded-xl after:border-b after:border-r after:border-black/20 after:-z-[1] after:-translate-y-0.5
                border border-white/5">
                <div className="text-4xl font-black text-white mb-2">+112 mi</div>
                <div className="text-lg text-white/90">Em Valor de Custo de Obra</div>
              </div>
            </div>
            
            <div className="relative group">
              {/* Glow effect */}
              <div className="absolute -inset-1 bg-white/20 rounded-2xl blur-xl group-hover:bg-white/30 transition-colors duration-500" />
              
              {/* Glass card */}
              <div className="relative p-8 rounded-xl bg-white/10 backdrop-blur-lg
                transform hover:scale-105 transition-all duration-300
                shadow-[0_8px_32px_rgba(255,255,255,0.1)]
                group-hover:shadow-[0_8px_32px_rgba(255,255,255,0.2)]
                text-center
                before:absolute before:inset-0 before:rounded-xl before:border-t before:border-l before:border-white/20 before:-z-[1] before:translate-y-0.5
                after:absolute after:inset-0 after:rounded-xl after:border-b after:border-r after:border-black/20 after:-z-[1] after:-translate-y-0.5
                border border-white/5">
                <div className="text-4xl font-black text-white mb-2">+52 Mil</div>
                <div className="text-lg text-white/90">M² em Área de Projeto</div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Home;