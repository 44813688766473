import { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { motion } from 'framer-motion';
import { cn } from '@/lib/utils';
import {
  ChevronFirst,
  ChevronLast,
  User2,
  FolderKanban,
  BarChart3,
  LogOut
} from 'lucide-react';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui/tooltip';

interface SidebarProps {
  onLogout: () => void;
}

export function Sidebar({ onLogout }: SidebarProps) {
  const [expanded, setExpanded] = useState<boolean>(true);
  const location = useLocation();

  const menuItems = [
    {
      id: 1,
      title: 'Perfil',
      icon: User2,
      link: '/member'
    },
    {
      id: 2,
      title: 'Projetos',
      icon: FolderKanban,
      link: '/member/projects'
    },
    {
      id: 3,
      title: 'Acompanhamento',
      icon: BarChart3,
      link: '/member/tracking'
    }
  ];

  const isActive = (path: string) => {
    if (path === '/member') {
      return location.pathname === '/member';
    }
    return location.pathname.startsWith(path);
  };

  return (
    <aside className="h-full">
      <nav className="h-full flex flex-col border-r border-white/10">
        <div className="p-8 pb-6 flex items-center justify-between relative">
          <div className={cn(
            'absolute left-0 right-0 mx-auto',
            'flex justify-center items-center overflow-hidden transition-all duration-300',
            expanded ? 'w-[180px] opacity-100' : 'w-0 opacity-0'
          )}>
            <div className="overflow-hidden flex items-center justify-center h-8 sm:h-10 md:h-12">
              <img
                src="/assets/logo.png"
                className="w-auto h-[200%] object-contain object-center max-w-none"
                alt="Visio Logo"
                loading="eager"
                style={{ background: 'transparent' }}
              />
            </div>
          </div>

          <div className="flex-1" />
          <button
            onClick={() => setExpanded((curr) => !curr)}
            className="p-1.5 rounded-lg bg-gray-50 hover:bg-gray-100 text-black flex-shrink-0 z-10"
          >
            {expanded ? <ChevronFirst /> : <ChevronLast />}
          </button>
        </div>

        <ul className="flex-1 px-3">
          {menuItems.map(({ id, title, icon: Icon, link }) => {
            const active = isActive(link);

            return (
              <TooltipProvider key={id}>
                <Tooltip>
                  <TooltipTrigger asChild>
                    <li className="flex justify-center">
                      <Link
                        to={link}
                        className={cn(
                          'relative flex items-center my-1',
                          'font-medium rounded-md cursor-pointer',
                          'transition-colors group',
                          expanded ? 'px-3 py-2 w-full' : 'w-10 h-10 justify-center',
                          active
                            ? 'text-white bg-white/10'
                            : 'hover:bg-[#1a1a1a]'
                        )}
                      >
                        <Icon className="w-5 h-5" />
                        <span
                          className={cn(
                            'overflow-hidden transition-all duration-300',
                            expanded ? 'ml-3 w-52 opacity-100' : 'w-0 opacity-0'
                          )}
                        >
                          {title}
                        </span>

                        {active && (
                          <motion.div
                            className="absolute inset-0 rounded-md bg-white/20"
                            layoutId="active"
                            transition={{
                              type: 'spring',
                              stiffness: 380,
                              damping: 30,
                            }}
                          />
                        )}
                      </Link>
                    </li>
                  </TooltipTrigger>
                  {!expanded && <TooltipContent side="right">{title}</TooltipContent>}
                </Tooltip>
              </TooltipProvider>
            );
          })}
        </ul>

        <div className="border-t border-white/10 p-3">
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger asChild>
                <div className="flex justify-center">
                  <button
                    onClick={onLogout}
                    className={cn(
                      'relative flex items-center my-1',
                      'font-medium rounded-md cursor-pointer',
                      'transition-colors group text-red-400',
                      expanded ? 'px-3 py-2 w-full justify-start' : 'w-10 h-10 justify-center',
                      'hover:bg-red-400/10'
                    )}
                  >
                    <LogOut className="w-5 h-5" />
                    <span
                      className={cn(
                        'overflow-hidden transition-all duration-300 text-red-400',
                        expanded ? 'ml-3 w-52 opacity-100' : 'w-0 opacity-0'
                      )}
                    >
                      Sair
                    </span>
                  </button>
                </div>
              </TooltipTrigger>
              {!expanded && <TooltipContent side="right">Sair</TooltipContent>}
            </Tooltip>
          </TooltipProvider>
        </div>
      </nav>
    </aside>
  );
}
