import { motion } from 'framer-motion';

export const ConstructionBackground = () => {
  return (
    <div className="fixed inset-0 -z-10 overflow-hidden">
      {/* Dark gradient background */}
      <div className="absolute inset-0 bg-gradient-to-br from-black via-premium-blue to-black opacity-90" />

      {/* Animated gradient overlay */}
      <motion.div
        className="absolute inset-0"
        animate={{
          background: [
            'radial-gradient(circle at 0% 0%, rgba(66, 153, 225, 0.05) 0%, transparent 50%)',
            'radial-gradient(circle at 100% 100%, rgba(66, 153, 225, 0.05) 0%, transparent 50%)',
            'radial-gradient(circle at 0% 0%, rgba(66, 153, 225, 0.05) 0%, transparent 50%)',
          ],
        }}
        transition={{
          duration: 15,
          repeat: Infinity,
          ease: "linear"
        }}
      />

      {/* Floating elements */}
      {Array.from({ length: 4 }).map((_, i) => (
        <motion.div
          key={i}
          className="absolute"
          style={{
            left: `${Math.random() * 100}%`,
            top: `${Math.random() * 100}%`,
          }}
          initial={{ scale: 0, opacity: 0 }}
          animate={{
            scale: [1, 1.1, 1],
            opacity: [0.2, 0.3, 0.2],
            y: [0, -20, 0],
          }}
          transition={{
            duration: Math.random() * 5 + 10,
            repeat: Infinity,
            ease: "easeInOut",
            delay: i * 0.5,
          }}
        >
          <div 
            className="w-32 h-32 rounded-2xl glass-panel"
            style={{
              transform: `rotate(${Math.random() * 360}deg)`,
            }}
          />
        </motion.div>
      ))}
    </div>
  );
};

export default ConstructionBackground;