import { DEFAULT_IMAGES } from '@/constants/defaultImages';

type PageKey = keyof typeof DEFAULT_IMAGES;

export const getPageImage = (pageKey: PageKey, uploadedImage?: string | null) => {
  if (!uploadedImage) {
    return DEFAULT_IMAGES[pageKey];
  }

  // Create an image element to test if the uploaded image is valid
  return new Promise<string>((resolve) => {
    const img = new Image();
    
    img.onload = () => {
      resolve(uploadedImage);
    };
    
    img.onerror = () => {
      console.warn(`Failed to load image: ${uploadedImage}, falling back to default`);
      resolve(DEFAULT_IMAGES[pageKey]);
    };

    img.src = uploadedImage;
  });
};
