import { Toaster as Sonner } from "sonner"

interface ToasterProps {
  richColors?: boolean;
  closeButton?: boolean;
  position?: "top-left" | "top-right" | "bottom-left" | "bottom-right" | "top-center" | "bottom-center";
  theme?: "light" | "dark";
  style?: React.CSSProperties;
  toastOptions?: {
    style?: React.CSSProperties;
    position?: "top-left" | "top-right" | "bottom-left" | "bottom-right" | "top-center" | "bottom-center";
    theme?: "light" | "dark";
    richColors?: boolean;
    closeButton?: boolean;
  };
}

export function CustomToaster({
  position = "top-right",
  theme = "dark",
  style,
  toastOptions,
  ...props
}: ToasterProps) {
  return (
    <Sonner
      className="toaster group"
      position={toastOptions?.position || position}
      theme={toastOptions?.theme || theme}
      style={{
        ...style,
        ...toastOptions?.style,
      }}
      richColors={toastOptions?.richColors}
      closeButton={toastOptions?.closeButton}
      {...props}
    />
  )
}