import { useState } from 'react';
import { Card } from '@/components/ui/card';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Textarea } from '@/components/ui/textarea';
import { Label } from '@/components/ui/label';
import { Dialog, DialogContent, DialogHeader, DialogTitle } from '@/components/ui/dialog';
import { Edit2, Save, Image as ImageIcon, PlusCircle, Trash2 } from 'lucide-react';
import { toast } from 'sonner';
import { ImageEditor } from './ImageEditor';
import { useContentStore } from '@/stores/contentStore';
import { ContentSection, FAQItem } from '@/types/content';

interface ContentEditorProps {
  section: 'home' | 'about' | 'manifesto' | 'contact' | 'faq';
  title?: string;
}

interface ListItem {
  id: string;
  title: string;
  description?: string;
  image?: string;
}

type EditableItem = ListItem | FAQItem;

function isFaqItem(item: EditableItem): item is FAQItem {
  return 'question' in item && 'answer' in item;
}

function isListItem(item: EditableItem): item is ListItem {
  return 'title' in item;
}

export function ContentEditor({ section, title = 'Editar Conteúdo' }: ContentEditorProps) {
  const { 
    home, about, manifesto, contact, faq,
    updateHome, updateAbout, updateManifesto, updateContact, updateFaq
  } = useContentStore();
  
  const [isEditing, setIsEditing] = useState(false);
  const [isImageEditing, setIsImageEditing] = useState(false);
  const [isListItemDialogOpen, setIsListItemDialogOpen] = useState(false);
  const [currentListItem, setCurrentListItem] = useState<EditableItem | null>(null);
  
  const content = {
    home,
    about,
    manifesto,
    contact,
    faq
  }[section];

  const updateFunction = {
    home: updateHome,
    about: updateAbout,
    manifesto: updateManifesto,
    contact: updateContact,
    faq: updateFaq
  }[section];

  const [formData, setFormData] = useState<ContentSection>(content);

  const handleChange = (field: keyof ContentSection, value: string) => {
    setFormData(prev => ({
      ...prev,
      [field]: value
    }));
  };

  const handleUpdateContent = () => {
    if (!updateFunction) return;
    
    updateFunction(formData);
    setIsEditing(false);
    toast.success('Conteúdo atualizado com sucesso!');
  };

  const handleUpdateListItem = (item: EditableItem) => {
    if (!updateFunction || !content) return;
    
    if (section === 'faq' && isFaqItem(item)) {
      const updatedContent = { ...content };
      const index = updatedContent.faqs?.findIndex(i => i.id === item.id) ?? -1;
      if (index >= 0 && updatedContent.faqs) {
        updatedContent.faqs[index] = item;
      } else if (updatedContent.faqs) {
        updatedContent.faqs.push(item);
      }

      updateFunction(updatedContent);
    } else if (isListItem(item)) {
      const listName = section === 'home' ? 'features' : 'items';
      const updatedContent = { ...content };
      
      if (!updatedContent[listName]) {
        updatedContent[listName] = [];
      }

      const index = updatedContent[listName]?.findIndex(i => i.id === item.id) ?? -1;
      if (index >= 0 && updatedContent[listName]) {
        updatedContent[listName][index] = item;
      } else if (updatedContent[listName]) {
        updatedContent[listName].push(item);
      }

      updateFunction(updatedContent);
    }
    
    setIsListItemDialogOpen(false);
    setCurrentListItem(null);
    toast.success('Item atualizado com sucesso!');
  };

  const handleDeleteListItem = (itemId: string) => {
    if (!updateFunction || !content) return;
    
    if (section === 'faq') {
      const updatedContent = { ...content };
      updatedContent.faqs = updatedContent.faqs?.filter(item => item.id !== itemId) || [];
      updateFunction(updatedContent);
    } else {
      const listName = section === 'home' ? 'features' : 'items';
      const updatedContent = { ...content };
      updatedContent[listName] = updatedContent[listName]?.filter(item => item.id !== itemId) || [];
      updateFunction(updatedContent);
    }
    
    toast.success('Item excluído com sucesso!');
  };

  const handleAddListItem = () => {
    if (section === 'faq') {
      const newFaq = {
        id: crypto.randomUUID(),
        question: '',
        answer: ''
      };
      setFormData({
        ...formData,
        faqs: [...(formData.faqs || []), newFaq]
      });
    } else {
      const newItem = {
        id: crypto.randomUUID(),
        title: '',
        description: '',
        image: ''
      };
      setFormData({
        ...formData,
        items: [...(formData.items || []), newItem]
      });
    }
    setIsListItemDialogOpen(true);
  };

  const handleEditListItem = (item: EditableItem) => {
    setCurrentListItem(item);
    setIsListItemDialogOpen(true);
  };

  const handleImageSelect = (imageUrl: string) => {
    setFormData(prev => ({
      ...prev,
      heroImage: imageUrl
    }));
    setIsImageEditing(false);
  };

  const renderListItemDialog = () => {
    if (!currentListItem) return null;

    if (isFaqItem(currentListItem)) {
      return (
        <Dialog open={isListItemDialogOpen} onOpenChange={setIsListItemDialogOpen}>
          <DialogContent>
            <DialogHeader>
              <DialogTitle>Editar FAQ</DialogTitle>
            </DialogHeader>
            <div className="space-y-4">
              <div>
                <Label>Pergunta</Label>
                <Input
                  value={currentListItem.question}
                  onChange={(e) => setCurrentListItem({
                    ...currentListItem,
                    question: e.target.value
                  })}
                />
              </div>
              <div>
                <Label>Resposta</Label>
                <Textarea
                  value={currentListItem.answer}
                  onChange={(e) => setCurrentListItem({
                    ...currentListItem,
                    answer: e.target.value
                  })}
                />
              </div>
              <Button onClick={() => handleUpdateListItem(currentListItem)}>
                Salvar
              </Button>
            </div>
          </DialogContent>
        </Dialog>
      );
    }

    if (isListItem(currentListItem)) {
      return (
        <Dialog open={isListItemDialogOpen} onOpenChange={setIsListItemDialogOpen}>
          <DialogContent>
            <DialogHeader>
              <DialogTitle>Editar Item</DialogTitle>
            </DialogHeader>
            <div className="space-y-4">
              <div>
                <Label>Título</Label>
                <Input
                  value={currentListItem.title}
                  onChange={(e) => setCurrentListItem({
                    ...currentListItem,
                    title: e.target.value
                  })}
                />
              </div>
              <div>
                <Label>Descrição</Label>
                <Textarea
                  value={currentListItem.description || ''}
                  onChange={(e) => setCurrentListItem({
                    ...currentListItem,
                    description: e.target.value
                  })}
                />
              </div>
              <div>
                <Label>Imagem URL</Label>
                <Input
                  value={currentListItem.image || ''}
                  onChange={(e) => setCurrentListItem({
                    ...currentListItem,
                    image: e.target.value
                  })}
                />
              </div>
              <Button onClick={() => handleUpdateListItem(currentListItem)}>
                Salvar
              </Button>
            </div>
          </DialogContent>
        </Dialog>
      );
    }

    return null;
  };

  if (!content) return null;

  return (
    <Card className="p-6 bg-black/40 backdrop-blur-xl border-white/10">
      <div className="flex justify-between items-center mb-6">
        <h3 className="text-xl font-semibold">{title}</h3>
        <div className="flex gap-2">
          {!isEditing && (
            <Button onClick={() => setIsEditing(true)}>
              <Edit2 className="h-4 w-4 mr-2" />
              Editar
            </Button>
          )}
          {isEditing && (
            <Button onClick={handleUpdateContent}>
              <Save className="h-4 w-4 mr-2" />
              Salvar
            </Button>
          )}
        </div>
      </div>

      <div className="space-y-6">
        {/* Hero Image */}
        <div className="space-y-4">
          <div className="flex items-center justify-between">
            <Label>Imagem do Hero</Label>
            <Button
              variant="outline"
              size="sm"
              onClick={() => setIsImageEditing(true)}
            >
              <ImageIcon className="h-4 w-4 mr-2" />
              {formData.heroImage ? 'Alterar Imagem' : 'Adicionar Imagem'}
            </Button>
          </div>
          {formData.heroImage ? (
            <div className="relative aspect-video rounded-lg overflow-hidden bg-black/20 group">
              <img
                src={formData.heroImage}
                alt="Hero"
                className="w-full h-full object-cover transition-transform duration-300 group-hover:scale-105"
              />
              <div className="absolute inset-0 bg-gradient-to-t from-black/60 via-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-300" />
              <div className="absolute inset-0 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                <Button
                  variant="outline"
                  size="sm"
                  className="bg-black/50 hover:bg-black/70"
                  onClick={() => setIsImageEditing(true)}
                >
                  <ImageIcon className="h-4 w-4 mr-2" />
                  Alterar Imagem
                </Button>
              </div>
            </div>
          ) : (
            <div 
              className="relative aspect-video rounded-lg overflow-hidden bg-black/20 border-2 border-dashed border-white/10 hover:border-white/20 transition-colors cursor-pointer group"
              onClick={() => setIsImageEditing(true)}
            >
              <div className="absolute inset-0 flex flex-col items-center justify-center text-white/60 group-hover:text-white/80 transition-colors">
                <ImageIcon className="h-8 w-8 mb-2" />
                <p>Clique para adicionar uma imagem</p>
                <p className="text-sm text-white/40 group-hover:text-white/60">Tamanho recomendado: 1920x1080px</p>
              </div>
            </div>
          )}
        </div>

        {/* Content Fields */}
        {isEditing ? (
          <div className="space-y-4">
            {section === 'home' && (
              <>
                <div className="space-y-2">
                  <Label>Título Principal</Label>
                  <Input
                    name="mainTitle"
                    value={formData.mainTitle || ''}
                    onChange={(e) => handleChange('mainTitle', e.target.value)}
                  />
                </div>
                <div className="space-y-2">
                  <Label>Subtítulo</Label>
                  <Input
                    name="subtitle"
                    value={formData.subtitle || ''}
                    onChange={(e) => handleChange('subtitle', e.target.value)}
                  />
                </div>
              </>
            )}

            {section !== 'home' && (
              <div className="space-y-2">
                <Label>Título</Label>
                <Input
                  name="title"
                  value={formData.title || ''}
                  onChange={(e) => handleChange('title', e.target.value)}
                />
              </div>
            )}

            <div className="space-y-2">
              <Label>Descrição</Label>
              <Textarea
                name="description"
                value={formData.description || ''}
                onChange={(e) => handleChange('description', e.target.value)}
                rows={6}
              />
            </div>

            {section === 'contact' && (
              <>
                <div className="space-y-2">
                  <Label>Email</Label>
                  <Input
                    name="email"
                    value={formData.email || ''}
                    onChange={(e) => handleChange('email', e.target.value)}
                  />
                </div>
                <div className="space-y-2">
                  <Label>Telefone</Label>
                  <Input
                    name="phone"
                    value={formData.phone || ''}
                    onChange={(e) => handleChange('phone', e.target.value)}
                  />
                </div>
                <div className="space-y-2">
                  <Label>Endereço</Label>
                  <Input
                    name="address"
                    value={formData.address || ''}
                    onChange={(e) => handleChange('address', e.target.value)}
                  />
                </div>
              </>
            )}
          </div>
        ) : (
          <div className="space-y-4">
            {section === 'home' ? (
              <>
                <h4 className="text-lg font-medium">{formData.mainTitle}</h4>
                <p className="text-gray-400">{formData.subtitle}</p>
              </>
            ) : (
              <h4 className="text-lg font-medium">{formData.title}</h4>
            )}
            <p className="whitespace-pre-wrap">{formData.description}</p>

            {section === 'contact' && (
              <div className="grid gap-4 mt-4">
                <div>
                  <Label>Email</Label>
                  <p className="text-gray-400">{formData.email}</p>
                </div>
                <div>
                  <Label>Telefone</Label>
                  <p className="text-gray-400">{formData.phone}</p>
                </div>
                <div>
                  <Label>Endereço</Label>
                  <p className="text-gray-400">{formData.address}</p>
                </div>
              </div>
            )}
          </div>
        )}
      </div>

      {/* List Items Section (for features, items, etc.) */}
      {(formData.features || formData.items || formData.faqs) && (
        <div className="space-y-4 mt-8">
          {section === 'faq' ? (
            <div className="space-y-4">
              <div className="flex items-center justify-between">
                <h3 className="text-lg font-medium">Perguntas Frequentes</h3>
                <Button
                  variant="outline"
                  size="sm"
                  onClick={handleAddListItem}
                  className="flex items-center gap-2"
                >
                  <PlusCircle className="h-4 w-4" />
                  Adicionar Pergunta
                </Button>
              </div>
              
              {formData.faqs?.map((faq, index) => (
                <Card key={faq.id} className="p-4 space-y-4">
                  <div className="flex items-start justify-between">
                    <div className="space-y-4 flex-1">
                      <div className="space-y-2">
                        <Label>Pergunta</Label>
                        <Input
                          value={faq.question}
                          onChange={(e) => {
                            const updatedFaqs = [...(formData.faqs || [])];
                            updatedFaqs[index] = {
                              ...faq,
                              question: e.target.value
                            };
                            setFormData({
                              ...formData,
                              faqs: updatedFaqs
                            });
                          }}
                          placeholder="Digite a pergunta..."
                        />
                      </div>
                      <div className="space-y-2">
                        <Label>Resposta</Label>
                        <Textarea
                          value={faq.answer}
                          onChange={(e) => {
                            const updatedFaqs = [...(formData.faqs || [])];
                            updatedFaqs[index] = {
                              ...faq,
                              answer: e.target.value
                            };
                            setFormData({
                              ...formData,
                              faqs: updatedFaqs
                            });
                          }}
                          placeholder="Digite a resposta..."
                          rows={4}
                        />
                      </div>
                    </div>
                    <Button
                      variant="ghost"
                      size="icon"
                      onClick={() => handleDeleteListItem(faq.id)}
                      className="text-red-500 hover:text-red-600 hover:bg-red-50"
                    >
                      <Trash2 className="h-4 w-4" />
                    </Button>
                  </div>
                </Card>
              ))}
            </div>
          ) : (
            <div className="flex justify-between items-center">
              <Label>
                {section === 'home' ? 'Recursos' : 'Itens'}
              </Label>
              <Button
                variant="outline"
                onClick={handleAddListItem}
              >
                <PlusCircle className="h-4 w-4 mr-2" />
                Adicionar
              </Button>
            </div>
          )}
          {(formData.features || formData.items)?.map((item: ListItem) => (
            <Card key={item.id} className="p-4 bg-black/20">
              <div className="flex justify-between items-start">
                <div>
                  <h4 className="font-medium">{item.title}</h4>
                  {item.description && (
                    <p className="text-sm text-gray-400 mt-1">{item.description}</p>
                  )}
                </div>
                <div className="flex gap-2">
                  <Button
                    variant="ghost"
                    size="icon"
                    onClick={() => handleEditListItem(item)}
                  >
                    <Edit2 className="h-4 w-4" />
                  </Button>
                  <Button
                    variant="ghost"
                    size="icon"
                    onClick={() => handleDeleteListItem(item.id)}
                  >
                    <Trash2 className="h-4 w-4" />
                  </Button>
                </div>
              </div>
            </Card>
          ))}
        </div>
      )}

      {/* Image Editor Dialog */}
      <Dialog open={isImageEditing} onOpenChange={setIsImageEditing}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Escolher Imagem</DialogTitle>
          </DialogHeader>
          <ImageEditor
            currentImage={formData.heroImage}
            onSelect={handleImageSelect}
          />
        </DialogContent>
      </Dialog>

      {renderListItemDialog()}
    </Card>
  );
}
