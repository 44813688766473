import { ChevronDown } from 'lucide-react';
import { useEffect, useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';

export const ScrollArrow = () => {
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      // Hide arrow when user scrolls down more than 100px
      setIsVisible(window.scrollY < 100);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <AnimatePresence>
      {isVisible && (
        <div className="absolute inset-x-0 bottom-4 md:bottom-6 z-50 pointer-events-none">
          <motion.div
            initial={{ opacity: 0, y: -20 }}
            animate={{ 
              opacity: [0.4, 1, 0.4], 
              y: [0, 20, 0],
              scale: [1, 1.1, 1],
              transition: {
                duration: 1.5,
                repeat: Infinity,
                ease: "easeInOut"
              }
            }}
            exit={{ opacity: 0, y: 20 }}
            className="w-12 h-12 mx-auto text-white/80"
          >
            <ChevronDown className="w-full h-full" />
          </motion.div>
        </div>
      )}
    </AnimatePresence>
  );
};
