import { Building2, Cpu, Users2 } from 'lucide-react';
import { ConstructionBackground } from '@/components/animations/ConstructionBackground';
import { useContentStore } from '@/stores/contentStore';
import { motion } from 'framer-motion';
import { DEFAULT_IMAGES } from '@/constants/defaultImages';
import { ScrollArrow } from '@/components/ScrollArrow';

const About = () => {
  const { about } = useContentStore();

  if (!about) return null;

  return (
    <div className="min-h-screen">
      {/* Hero Section */}
      <section className="min-h-[90vh] flex items-center justify-center relative overflow-hidden">
        <div 
          className="absolute inset-0 bg-cover bg-center bg-no-repeat"
          style={{
            backgroundImage: `url(${about.heroImage || about.bannerImage || DEFAULT_IMAGES.about})`,
            backgroundPosition: 'center 30%'
          }}
        >
          <div className="absolute inset-0 bg-gradient-to-b from-black/80 via-black/50 to-transparent" />
        </div>

        {/* Black fade for smooth transition */}
        <div className="absolute bottom-0 left-0 right-0 h-32 bg-gradient-to-t from-black to-transparent" />

        <div className="relative z-10 text-center max-w-6xl mx-auto px-4 mt-32 md:mt-16">
          <h1 
            className="text-[2rem] md:text-[2.75rem] leading-tight font-bold text-white mb-3 max-w-6xl mx-auto"
          >
            {about.title}
          </h1>
          <p
            className="text-xl text-white/90 max-w-6xl mx-auto mt-6"
          >
            {about.subtitle}
          </p>
          <p className="text-white/70 mt-4 max-w-4xl mx-auto">
            {about.description}
          </p>
        </div>
        <ScrollArrow />
      </section>
      
      {/* Features Section */}
      <section className="min-h-[90vh] bg-black relative">
        <div className="absolute inset-0 bg-[radial-gradient(circle_at_bottom_left,_var(--tw-gradient-stops))] from-white/[0.03] to-transparent" />
        
        {/* Content wrapper for vertical centering */}
        <div className="absolute inset-0 flex items-center">
          <div className="w-full max-w-7xl mx-auto px-8">
            <div className="grid md:grid-cols-3 gap-8">
              <div className="relative group">
                {/* Glow effect */}
                <div className="absolute -inset-1 bg-white/20 rounded-2xl blur-xl group-hover:bg-white/30 transition-colors duration-500" />
                
                {/* Glass card */}
                <div className="relative p-8 rounded-xl bg-white/10 backdrop-blur-lg
                  transform hover:scale-105 transition-all duration-300
                  shadow-[0_8px_32px_rgba(255,255,255,0.1)]
                  group-hover:shadow-[0_8px_32px_rgba(255,255,255,0.2)]
                  before:absolute before:inset-0 before:rounded-xl before:border-t before:border-l before:border-white/20 before:-z-[1] before:translate-y-0.5
                  after:absolute after:inset-0 after:rounded-xl after:border-b after:border-r after:border-black/20 after:-z-[1] after:-translate-y-0.5
                  border border-white/5 h-full">
                  <div className="h-8 w-8 mb-4">
                    <Building2 className="w-full h-full text-white" />
                  </div>
                  <h3 className="text-xl font-semibold mb-2 text-white">Arquitetos e Construtores</h3>
                  <p className="text-white/90">Otimize seus projetos com orçamentos precisos e assertivos</p>
                </div>
              </div>

              <div className="relative group">
                {/* Glow effect */}
                <div className="absolute -inset-1 bg-white/20 rounded-2xl blur-xl group-hover:bg-white/30 transition-colors duration-500" />
                
                {/* Glass card */}
                <div className="relative p-8 rounded-xl bg-white/10 backdrop-blur-lg
                  transform hover:scale-105 transition-all duration-300
                  shadow-[0_8px_32px_rgba(255,255,255,0.1)]
                  group-hover:shadow-[0_8px_32px_rgba(255,255,255,0.2)]
                  before:absolute before:inset-0 before:rounded-xl before:border-t before:border-l before:border-white/20 before:-z-[1] before:translate-y-0.5
                  after:absolute after:inset-0 after:rounded-xl after:border-b after:border-r after:border-black/20 after:-z-[1] after:-translate-y-0.5
                  border border-white/5 h-full">
                  <div className="h-8 w-8 mb-4">
                    <Users2 className="w-full h-full text-white" />
                  </div>
                  <h3 className="text-xl font-semibold mb-2 text-white">Donos de Obras</h3>
                  <p className="text-white/90">Melhore a gestão de custos da sua obra com uma ferramenta objetiva e inovadora</p>
                </div>
              </div>

              <div className="relative group">
                {/* Glow effect */}
                <div className="absolute -inset-1 bg-white/20 rounded-2xl blur-xl group-hover:bg-white/30 transition-colors duration-500" />
                
                {/* Glass card */}
                <div className="relative p-8 rounded-xl bg-white/10 backdrop-blur-lg
                  transform hover:scale-105 transition-all duration-300
                  shadow-[0_8px_32px_rgba(255,255,255,0.1)]
                  group-hover:shadow-[0_8px_32px_rgba(255,255,255,0.2)]
                  before:absolute before:inset-0 before:rounded-xl before:border-t before:border-l before:border-white/20 before:-z-[1] before:translate-y-0.5
                  after:absolute after:inset-0 after:rounded-xl after:border-b after:border-r after:border-black/20 after:-z-[1] after:-translate-y-0.5
                  border border-white/5 h-full">
                  <div className="h-8 w-8 mb-4">
                    <Cpu className="w-full h-full text-white" />
                  </div>
                  <h3 className="text-xl font-semibold mb-2 text-white">Tecnologia Avançada</h3>
                  <p className="text-white/90">Integramos inteligência artificial à EAPs para entregar orçamentos detalhados e gerenciáveis</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default About;