import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Dialog, DialogContent, DialogHeader, DialogTitle } from '@/components/ui/dialog';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Checkbox } from '@/components/ui/checkbox';
import { Eye, EyeOff, Mail, Lock, X } from 'lucide-react';
import { useAuth } from '@/contexts/AuthContext';
import { Link } from 'react-router-dom';
import { toast } from 'sonner';
import { RegisterDialog } from './RegisterDialog';

export interface LoginDialogProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
}

export function LoginDialog({ open, onOpenChange }: LoginDialogProps) {
  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [rememberMe, setRememberMe] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showRegister, setShowRegister] = useState(false);
  const { login } = useAuth();
  const navigate = useNavigate();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);

    try {
      await login(email, password);
      
      toast.success('Login realizado com sucesso!');
      onOpenChange(false);
      navigate('/member/profile');
    } catch (error: any) {
      console.error('Login error in dialog:', error);
      toast.error(error?.message || 'Erro ao fazer login. Por favor, verifique suas credenciais.');
    } finally {
      setLoading(false);
    }
  };

  const handleRegisterClick = () => {
    onOpenChange(false);
    setShowRegister(true);
  };

  return (
    <>
      <Dialog open={open} onOpenChange={onOpenChange}>
        <DialogContent className="max-w-md bg-black/40 border-white/20 backdrop-blur-2xl shadow-xl 
          before:absolute before:inset-0 before:bg-gradient-to-b before:from-white/5 before:to-transparent before:rounded-lg before:-z-10
          after:absolute after:inset-0 after:bg-gradient-to-t after:from-white/5 after:to-transparent after:rounded-lg after:-z-10">
          <DialogHeader>
            <DialogTitle>Login</DialogTitle>
            <div className="text-sm text-white/70">
              Entre com suas credenciais para acessar sua conta
            </div>
          </DialogHeader>
          
          <button
            onClick={() => onOpenChange(false)}
            className="absolute right-4 top-4 rounded-sm opacity-70 ring-offset-background transition-opacity hover:opacity-100 focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 disabled:pointer-events-none data-[state=open]:bg-accent data-[state=open]:text-muted-foreground"
          >
            <X className="h-4 w-4 text-white" />
            <span className="sr-only">Close</span>
          </button>

          <div className="flex flex-col items-center justify-center min-h-[400px]">
            <div className="w-48 h-16 relative mb-8 overflow-hidden">
              <img
                src="/assets/logo.png"
                alt="Visio Partners"
                className="absolute inset-0 w-full h-full object-contain"
                style={{ 
                  background: 'transparent',
                  transform: 'scale(2.2)',
                  transformOrigin: 'center' 
                }}
              />
            </div>

            <h2 className="text-2xl font-bold bg-gradient-to-r from-white to-white/80 bg-clip-text text-transparent mb-8">
              Bem-vindo de volta
            </h2>

            <form onSubmit={handleSubmit} className="space-y-6 w-full">
              <div className="space-y-2">
                <label htmlFor="email" className="text-white/90">Email</label>
                <div className="relative">
                  <Mail className="absolute left-3 top-1/2 -translate-y-1/2 h-4 w-4 text-white/50" />
                  <Input
                    id="email"
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="bg-white/10 border-white/20 text-white placeholder:text-white/50 focus:border-white/30 focus:ring-white/20 pl-10"
                    placeholder="seu@email.com"
                    required
                  />
                </div>
              </div>

              <div className="space-y-2">
                <label htmlFor="password" className="text-white/90">Senha</label>
                <div className="relative">
                  <Lock className="absolute left-3 top-1/2 -translate-y-1/2 h-4 w-4 text-white/50" />
                  <Input
                    id="password"
                    type={showPassword ? 'text' : 'password'}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    className="bg-white/10 border-white/20 text-white placeholder:text-white/50 focus:border-white/30 focus:ring-white/20 pl-10"
                    placeholder="••••••••"
                    required
                  />
                  <button
                    type="button"
                    onClick={() => setShowPassword(!showPassword)}
                    className="absolute right-3 top-1/2 -translate-y-1/2 text-white/50 hover:text-white"
                  >
                    {showPassword ? <EyeOff className="h-4 w-4" /> : <Eye className="h-4 w-4" />}
                  </button>
                </div>
              </div>

              <div className="flex items-center justify-between">
                <div className="flex items-center space-x-2">
                  <Checkbox
                    id="remember"
                    checked={rememberMe}
                    onCheckedChange={(checked) => setRememberMe(checked as boolean)}
                  />
                  <label
                    htmlFor="remember"
                    className="text-sm text-white/70 cursor-pointer"
                  >
                    Lembrar-me
                  </label>
                </div>
                <button
                  type="button"
                  className="text-sm text-white/70 hover:text-white"
                >
                  Esqueceu a senha?
                </button>
              </div>

              <Button
                type="submit"
                className="w-full bg-white hover:bg-white/90 text-black font-medium py-2 px-4 rounded-lg transition-all duration-200 hover:scale-[1.02] active:scale-[0.98]"
                disabled={loading}
              >
                {loading ? (
                  <div className="flex items-center justify-center gap-2">
                    <div className="h-4 w-4 animate-spin border-2 border-white border-t-transparent rounded-full"></div>
                    <span>Entrando...</span>
                  </div>
                ) : (
                  'Entrar'
                )}
              </Button>

              <div className="relative my-6">
                <div className="absolute inset-0 flex items-center">
                  <div className="w-full border-t border-white/10"></div>
                </div>
                <div className="relative flex justify-center text-xs uppercase">
                  <span className="bg-transparent px-2 text-white/70">Ou</span>
                </div>
              </div>

              <p className="text-center text-sm text-white/50">
                Não tem uma conta?{' '}
                <button
                  type="button"
                  onClick={handleRegisterClick}
                  className="text-white hover:underline"
                >
                  Cadastre-se
                </button>
              </p>
            </form>
          </div>
        </DialogContent>
      </Dialog>

      <RegisterDialog
        open={showRegister}
        onOpenChange={setShowRegister}
      />
    </>
  );
}