import { motion } from 'framer-motion';
import { useContentStore } from '@/stores/contentStore';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion";
import { DEFAULT_IMAGES } from '@/constants/defaultImages';
import { getPageImage } from '@/utils/imageHelpers';
import { useEffect, useState } from 'react';
import { cn } from '@/lib/utils';

export default function FAQ() {
  const { faq } = useContentStore();
  const [imageLoaded, setImageLoaded] = useState(false);
  const [currentImage, setCurrentImage] = useState<string>(DEFAULT_IMAGES.faq);

  useEffect(() => {
    const loadImage = async () => {
      const imageUrl = await getPageImage('faq', faq?.heroImage || faq?.bannerImage);
      setCurrentImage(imageUrl);
      setImageLoaded(true);
    };

    loadImage();
  }, [faq?.heroImage, faq?.bannerImage]);

  if (!faq) return null;

  return (
    <div className="min-h-screen bg-black text-white">
      {/* Hero Section */}
      <section className="min-h-[90vh] flex items-center justify-center relative overflow-hidden">
        <div 
          className="absolute inset-0 bg-cover bg-center bg-no-repeat"
          style={{
            backgroundImage: `url(${currentImage})`,
          }}
        >
          <div className="absolute inset-0 bg-gradient-to-b from-black/80 via-black/50 to-transparent" />
        </div>

        <div className="relative z-10 text-center max-w-6xl mx-auto px-4 mt-32 md:mt-16">
          <h1 
            className="text-[2rem] md:text-[2.75rem] leading-tight font-bold text-white mb-3 max-w-6xl mx-auto"
          >
            {faq.title}
          </h1>
          <p
            className="text-xl text-white/90 max-w-6xl mx-auto mt-6"
          >
            {faq.subtitle}
          </p>
        </div>
      </section>

      {/* FAQ Section with Glass Effect */}
      <section className="relative z-10 -mt-20 pb-20">
        <div className="container max-w-6xl mx-auto px-4">
          {/* Glowing background effect */}
          <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 w-full max-w-5xl h-[120%] blur-[100px] bg-gradient-to-b from-white/20 via-white/5 to-transparent rounded-full" />
          
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            className="relative backdrop-blur-xl bg-white/95 border border-white/40 rounded-2xl p-8 shadow-[0_0_50px_rgba(255,255,255,0.15)] overflow-hidden"
          >
            {/* Inner glow */}
            <div className="absolute inset-0 bg-gradient-radial from-white to-transparent opacity-30 pointer-events-none" />
            
            {/* Title */}
            <div className="relative z-10 text-center mb-8">
              <h2 className="text-3xl font-bold text-gray-600 inline-block relative">
                FAQ
                <div className="absolute inset-0 blur-sm bg-gray-400/30 -z-10" aria-hidden="true" />
              </h2>
            </div>
            
            <Accordion type="single" collapsible className="w-full space-y-4 relative z-10">
              {faq.faqs?.map((item) => (
                <AccordionItem
                  key={item.id}
                  value={item.id}
                  className="border-b border-black/10 last:border-b-0"
                >
                  <AccordionTrigger className="text-lg font-medium text-black/80 hover:text-black py-4 transition-colors duration-200">
                    {item.question}
                  </AccordionTrigger>
                  <AccordionContent className="text-black/70 pb-6">
                    {item.answer}
                  </AccordionContent>
                </AccordionItem>
              ))}
            </Accordion>
          </motion.div>
        </div>
      </section>
    </div>
  );
}