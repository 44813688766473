import { Card } from '@/components/ui/card';
import { motion } from 'framer-motion';

const Terms = () => {
  return (
    <div className="min-h-screen pt-40 pb-32">
      <div className="max-w-7xl mx-auto px-8">
        <motion.h1 
          className="text-5xl font-bold mb-12 text-center"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
        >
          Termos de Uso
        </motion.h1>

        <Card className="p-12 bg-black/40 backdrop-blur-xl border-white/10">
          <div className="prose prose-invert max-w-none prose-lg">
            <h2>1. Termos</h2>
            <p>Ao acessar ao site Visio Partners, concorda em cumprir estes termos de serviço, todas as leis e regulamentos aplicáveis e concorda que é responsável pelo cumprimento de todas as leis locais aplicáveis. Se você não concordar com algum desses termos, está proibido de usar ou acessar este site. Os materiais contidos neste site são protegidos pelas leis de direitos autorais e marcas comerciais aplicáveis.</p>

            <h2>2. Uso de Licença</h2>
            <p>É concedida permissão para baixar temporariamente uma cópia dos materiais (informações ou software) no site Visio Partners, apenas para visualização transitória pessoal e não comercial. Esta é a concessão de uma licença, não uma transferência de título e, sob esta licença, você não pode:</p>
            <ul>
              <li>modificar ou copiar os materiais;</li>
              <li>usar os materiais para qualquer finalidade comercial ou para exibição pública (comercial ou não comercial);</li>
              <li>tentar descompilar ou fazer engenharia reversa de qualquer software contido no site Visio Partners;</li>
              <li>remover quaisquer direitos autorais ou outras notações de propriedade dos materiais; ou</li>
              <li>transferir os materiais para outra pessoa ou 'espelhar' os materiais em qualquer outro servidor.</li>
            </ul>

            <h2>3. Isenção de responsabilidade</h2>
            <p>Os materiais no site da Visio Partners são fornecidos 'como estão'. Visio Partners não oferece garantias, expressas ou implícitas, e, por este meio, isenta e nega todas as outras garantias, incluindo, sem limitação, garantias implícitas ou condições de comercialização, adequação a um fim específico ou não violação de propriedade intelectual ou outra violação de direitos.</p>

            <h2>4. Limitações</h2>
            <p>Em nenhum caso o Visio Partners ou seus fornecedores serão responsáveis por quaisquer danos (incluindo, sem limitação, danos por perda de dados ou lucro ou devido a interrupção dos negócios) decorrentes do uso ou da incapacidade de usar os materiais em Visio Partners, mesmo que Visio Partners ou um representante autorizado da Visio Partners tenha sido notificado oralmente ou por escrito da possibilidade de tais danos.</p>

            <h2>5. Precisão dos materiais</h2>
            <p>Os materiais exibidos no site da Visio Partners podem incluir erros técnicos, tipográficos ou fotográficos. Visio Partners não garante que qualquer material em seu site seja preciso, completo ou atual. Visio Partners pode fazer alterações nos materiais contidos em seu site a qualquer momento, sem aviso prévio. No entanto, Visio Partners não se compromete a atualizar os materiais.</p>
          </div>
        </Card>
      </div>
    </div>
  );
};

export default Terms;