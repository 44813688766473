import { Card } from '@/components/ui/card';
import { motion } from 'framer-motion';

const Privacy = () => {
  return (
    <div className="min-h-screen pt-40 pb-32">
      <div className="max-w-7xl mx-auto px-8">
        <motion.h1 
          className="text-5xl font-bold mb-12 text-center"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
        >
          Política de Privacidade
        </motion.h1>

        <Card className="p-12 bg-black/40 backdrop-blur-xl border-white/10">
          <div className="prose prose-invert max-w-none prose-lg">
            <p>A sua privacidade é importante para nós. É política do Visio Partners respeitar a sua privacidade em relação a qualquer informação sua que possamos coletar no site Visio Partners, e outros sites que possuímos e operamos.</p>

            <h2>1. Informações que coletamos</h2>
            <p>Solicitamos informações pessoais apenas quando realmente precisamos delas para lhe fornecer um serviço. Fazemo-lo por meios justos e legais, com o seu conhecimento e consentimento. Também informamos por que estamos coletando e como será usado.</p>

            <h2>2. Uso de suas informações pessoais</h2>
            <p>Apenas retemos as informações coletadas pelo tempo necessário para fornecer o serviço solicitado. Quando armazenamos dados, protegemos dentro de meios comercialmente aceitáveis para evitar perdas e roubos, bem como acesso, divulgação, cópia, uso ou modificação não autorizados.</p>

            <h2>3. Compromisso do Usuário</h2>
            <p>O usuário se compromete a fazer uso adequado dos conteúdos e da informação que o Visio Partners oferece no site e com caráter enunciativo, mas não limitativo:</p>
            <ul>
              <li>Não se envolver em atividades que sejam ilegais ou contrárias à boa fé a à ordem pública;</li>
              <li>Não difundir propaganda ou conteúdo de natureza racista, xenofóbica, ou sobre cassinos, apostas online (ex.: Betano), jogos de sorte e azar, qualquer tipo de pornografia ilegal, de apologia ao terrorismo ou contra os direitos humanos;</li>
              <li>Não causar danos aos sistemas físicos (hardwares) e lógicos (softwares) do Visio Partners, de seus fornecedores ou terceiros.</li>
            </ul>

            <h2>4. Mais informações</h2>
            <p>Esperemos que esteja esclarecido e, como mencionado anteriormente, se houver algo que você não tem certeza se precisa ou não, geralmente é mais seguro deixar os cookies ativados, caso interaja com um dos recursos que você usa em nosso site.</p>
          </div>
        </Card>
      </div>
    </div>
  );
};

export default Privacy;