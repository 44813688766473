import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

declare global {
  interface Window {
    gtag: (
      command: 'config' | 'event',
      targetId: string,
      config?: Record<string, any>
    ) => void;
    dataLayer: any[];
  }
}

const GA_TRACKING_ID = 'G-DJDR0LNXXL';

export const useGoogleAnalytics = () => {
  const location = useLocation();

  useEffect(() => {
    try {
      // Skip tracking in development
      if (process.env.NODE_ENV === 'development') {
        return;
      }

      // Ensure gtag is available
      if (typeof window.gtag === 'undefined') {
        console.warn('Google Analytics not loaded');
        return;
      }

      // Send pageview with path and title
      window.gtag('config', GA_TRACKING_ID, {
        page_path: location.pathname + location.search,
        page_title: document.title,
        page_location: window.location.href
      });
    } catch (error) {
      // Silently handle errors in production, log in development
      if (process.env.NODE_ENV === 'development') {
        console.error('Google Analytics error:', error);
      }
    }
  }, [location]);
};
