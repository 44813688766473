import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Dialog, DialogContent, DialogHeader, DialogTitle } from '@/components/ui/dialog';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Eye, EyeOff, User, Mail, Lock, Building, X, CheckCircle2 } from 'lucide-react';
import { useAuth } from '@/contexts/AuthContext';
import { register } from '@/lib/api';
import { updateProjectUserId } from '@/lib/project-service';
import { toast } from 'sonner';

interface RegisterDialogProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
}

export function RegisterDialog({ open, onOpenChange }: RegisterDialogProps) {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    password: '',
    confirmPassword: '',
    company: ''
  });
  const { login, updateUser } = useAuth();
  const navigate = useNavigate();

  // Load email from sessionStorage when dialog opens
  useEffect(() => {
    if (open) {
      const pendingEmail = sessionStorage.getItem('pendingRegistrationEmail');
      if (pendingEmail) {
        setFormData(prev => ({ ...prev, email: pendingEmail }));
        sessionStorage.removeItem('pendingRegistrationEmail');
      }
    }
  }, [open]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);

    try {
      if (formData.password !== formData.confirmPassword) {
        throw new Error('As senhas não conferem');
      }

      // Register the user
      console.log('Starting registration...');
      const registerResponse = await register({
        name: formData.name,
        email: formData.email,
        password: formData.password,
        company: formData.company
      });

      console.log('Registration successful:', {
        hasToken: !!registerResponse?.token,
        hasUser: !!registerResponse?.user,
        token: registerResponse?.token,
        user: registerResponse?.user
      });

      if (!registerResponse?.token || !registerResponse?.user) {
        throw new Error('Erro ao registrar usuário: dados inválidos');
      }

      // Store registration data
      localStorage.setItem('token', registerResponse.token);

      // Process user data
      const userData = {
        ...registerResponse.user,
        id: registerResponse.user.id || registerResponse.user.sub || registerResponse.user._id,
        role: registerResponse.user.role || 'user',
        email: formData.email
      };

      // Update auth context
      updateUser(userData);

      // Show success message
      setShowSuccess(true);
      toast.success('Conta criada com sucesso!');

      // Close dialog and redirect to member area
      setTimeout(() => {
        setShowSuccess(false);
        onOpenChange(false);
        navigate('/member/profile');
      }, 1500);

    } catch (error: any) {
      console.error('Registration error:', error);
      toast.error(error.message || 'Erro ao criar conta');
    } finally {
      setLoading(false);
    }
  };

  if (showSuccess) {
    return (
      <Dialog open={true} onOpenChange={() => {}}>
        <DialogContent className="max-w-md bg-black/40 border-white/20 backdrop-blur-2xl shadow-xl 
          before:absolute before:inset-0 before:bg-gradient-to-b before:from-white/5 before:to-transparent before:rounded-lg before:-z-10
          after:absolute after:inset-0 after:bg-gradient-to-t after:from-white/5 after:to-transparent after:rounded-lg after:-z-10">
          <DialogHeader>
            <DialogTitle className="sr-only">Registro bem-sucedido</DialogTitle>
          </DialogHeader>
          <div className="flex flex-col items-center justify-center py-8 px-4 text-center">
            <CheckCircle2 className="h-16 w-16 text-green-500 mb-4" />
            <h2 className="text-2xl font-bold text-white mb-2">
              Conta criada com sucesso!
            </h2>
            <p className="text-white/80">
              Aguarde, você será redirecionado
            </p>
          </div>
        </DialogContent>
      </Dialog>
    );
  }

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="sm:max-w-[425px] bg-black/40 border-white/20 backdrop-blur-2xl shadow-xl 
        before:absolute before:inset-0 before:bg-gradient-to-b before:from-white/5 before:to-transparent before:rounded-lg before:-z-10
        after:absolute after:inset-0 after:bg-gradient-to-t after:from-white/5 after:to-transparent after:rounded-lg after:-z-10">
        <DialogHeader>
          <DialogTitle>Criar Conta</DialogTitle>
          <div className="text-sm text-muted-foreground">
            Preencha os dados abaixo para criar sua conta
          </div>
        </DialogHeader>
        
        <button
          onClick={() => onOpenChange(false)}
          className="absolute right-4 top-4 rounded-sm opacity-70 ring-offset-background transition-opacity hover:opacity-100 focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 disabled:pointer-events-none data-[state=open]:bg-accent data-[state=open]:text-muted-foreground"
        >
          <X className="h-4 w-4 text-white" />
          <span className="sr-only">Close</span>
        </button>

        <div className="flex flex-col items-center justify-center">
          <div className="w-48 h-16 relative mb-8 overflow-hidden">
            <img
              src="/assets/logo.png"
              alt="Visio Partners"
              className="absolute inset-0 w-full h-full object-contain"
              style={{ 
                background: 'transparent',
                transform: 'scale(2.2)',
                transformOrigin: 'center' 
              }}
            />
          </div>

          <h2 className="text-2xl font-bold bg-gradient-to-r from-white to-white/80 bg-clip-text text-transparent mb-8">
            Criar nova conta
          </h2>

          <form onSubmit={handleSubmit} className="space-y-6 w-full">
            <div className="space-y-2">
              <label htmlFor="name" className="text-white/90">Nome completo</label>
              <div className="relative">
                <User className="absolute left-3 top-1/2 -translate-y-1/2 h-4 w-4 text-white/50" />
                <Input
                  id="name"
                  type="text"
                  value={formData.name}
                  onChange={(e) => setFormData(prev => ({ ...prev, name: e.target.value }))}
                  className="bg-white/10 border-white/20 text-white placeholder:text-white/50 focus:border-white/30 focus:ring-white/20 pl-10"
                  placeholder="Seu nome"
                  required
                />
              </div>
            </div>

            <div className="space-y-2">
              <label htmlFor="email" className="text-white/90">Email</label>
              <div className="relative">
                <Mail className="absolute left-3 top-1/2 -translate-y-1/2 h-4 w-4 text-white/50" />
                <Input
                  id="email"
                  type="email"
                  value={formData.email}
                  onChange={(e) => setFormData(prev => ({ ...prev, email: e.target.value }))}
                  className="bg-white/10 border-white/20 text-white placeholder:text-white/50 focus:border-white/30 focus:ring-white/20 pl-10"
                  placeholder="seu@email.com"
                  required
                />
              </div>
            </div>

            <div className="space-y-2">
              <label htmlFor="password" className="text-white/90">Senha</label>
              <div className="relative">
                <Lock className="absolute left-3 top-1/2 -translate-y-1/2 h-4 w-4 text-white/50" />
                <Input
                  id="password"
                  type={showPassword ? "text" : "password"}
                  value={formData.password}
                  onChange={(e) => setFormData(prev => ({ ...prev, password: e.target.value }))}
                  className="bg-white/10 border-white/20 text-white placeholder:text-white/50 focus:border-white/30 focus:ring-white/20 pl-10 pr-10"
                  placeholder="••••••••"
                  required
                />
                <button
                  type="button"
                  onClick={() => setShowPassword(!showPassword)}
                  className="absolute right-3 top-1/2 -translate-y-1/2"
                >
                  {showPassword ? (
                    <EyeOff className="h-4 w-4 text-white/50" />
                  ) : (
                    <Eye className="h-4 w-4 text-white/50" />
                  )}
                  <span className="sr-only">
                    {showPassword ? "Hide password" : "Show password"}
                  </span>
                </button>
              </div>
            </div>

            <div className="space-y-2">
              <label htmlFor="confirmPassword" className="text-white/90">Confirmar senha</label>
              <div className="relative">
                <Lock className="absolute left-3 top-1/2 -translate-y-1/2 h-4 w-4 text-white/50" />
                <Input
                  id="confirmPassword"
                  type={showConfirmPassword ? "text" : "password"}
                  value={formData.confirmPassword}
                  onChange={(e) => setFormData(prev => ({ ...prev, confirmPassword: e.target.value }))}
                  className="bg-white/10 border-white/20 text-white placeholder:text-white/50 focus:border-white/30 focus:ring-white/20 pl-10 pr-10"
                  placeholder="••••••••"
                  required
                />
                <button
                  type="button"
                  onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                  className="absolute right-3 top-1/2 -translate-y-1/2"
                >
                  {showConfirmPassword ? (
                    <EyeOff className="h-4 w-4 text-white/50" />
                  ) : (
                    <Eye className="h-4 w-4 text-white/50" />
                  )}
                  <span className="sr-only">
                    {showConfirmPassword ? "Hide password" : "Show password"}
                  </span>
                </button>
              </div>
            </div>

            <div className="space-y-2">
              <label htmlFor="company" className="text-white/90">Empresa (opcional)</label>
              <div className="relative">
                <Building className="absolute left-3 top-1/2 -translate-y-1/2 h-4 w-4 text-white/50" />
                <Input
                  id="company"
                  type="text"
                  value={formData.company}
                  onChange={(e) => setFormData(prev => ({ ...prev, company: e.target.value }))}
                  className="bg-white/10 border-white/20 text-white placeholder:text-white/50 focus:border-white/30 focus:ring-white/20 pl-10"
                  placeholder="Nome da empresa"
                />
              </div>
            </div>

            <Button
              type="submit"
              disabled={loading}
              className="w-full bg-white hover:bg-white/90 text-black"
            >
              {loading ? "Criando conta..." : "Criar conta"}
            </Button>
          </form>
        </div>
      </DialogContent>
    </Dialog>
  );
}