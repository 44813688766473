import { Link } from 'react-router-dom';
import { Instagram, Linkedin } from 'lucide-react';

const Footer = () => {
  return (
    <footer className="w-full bg-black/50 backdrop-blur-lg border-t border-white/10">
      <div className="max-w-6xl mx-auto px-4 py-8">
        <div className="flex flex-col items-center justify-center gap-6">
          <div className="flex flex-col md:flex-row items-center justify-center gap-6 text-white">
            <Link to="/terms" className="hover:text-glow transition-all">
              Termos de uso
            </Link>
            <span className="hidden md:inline text-white/40">•</span>
            <Link to="/data-policy" className="hover:text-glow transition-all">
              Política de dados
            </Link>
            <span className="hidden md:inline text-white/40">•</span>
            <Link to="/privacy" className="hover:text-glow transition-all">
              Política de privacidade
            </Link>
          </div>

          <div className="flex items-center justify-center gap-4">
            <a 
              href="https://instagram.com/visio.partners" 
              target="_blank" 
              rel="noopener noreferrer"
              className="text-white hover:text-glow transition-all"
            >
              <Instagram className="h-6 w-6" />
            </a>
            <a 
              href="https://linkedin.com/company/visio-partners-br" 
              target="_blank" 
              rel="noopener noreferrer"
              className="text-white hover:text-glow transition-all"
            >
              <Linkedin className="h-6 w-6" />
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;