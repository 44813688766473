import axios from 'axios';
import type { Project } from '@/types/project';

// Constants for file size limits
const MAX_FILE_SIZE = 50 * 1024 * 1024; // 50MB per file
const MAX_TOTAL_SIZE = 200 * 1024 * 1024; // 200MB total

const DB_NAME = 'visio-projects-db';
const DB_VERSION = 1;
const PROJECTS_STORE = 'projects';
const FILES_STORE = 'files';

interface ProjectData {
  name: string;
  email: string;
  phone: string;
  description: string;
  deliverables: string[];
  files: File[];
  userId?: string;
  type: string;
}

interface UploadResponse {
  success: boolean;
  reference?: string;
  message?: string;
  error?: string;
  project?: Project;
}

function formatFileSize(bytes: number): string {
  if (bytes === 0) return '0 Bytes';
  const k = 1024;
  const sizes = ['Bytes', 'KB', 'MB', 'GB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
}

function validateFiles(files: File[]): void {
  const totalSize = files.reduce((sum, file) => sum + file.size, 0);
  if (totalSize > MAX_TOTAL_SIZE) {
    throw new Error(`O tamanho total dos arquivos (${formatFileSize(totalSize)}) excede o limite de ${formatFileSize(MAX_TOTAL_SIZE)}`);
  }

  for (const file of files) {
    if (file.size > MAX_FILE_SIZE) {
      throw new Error(`O arquivo ${file.name} (${formatFileSize(file.size)}) excede o limite de ${formatFileSize(MAX_FILE_SIZE)}`);
    }
  }
}

// Initialize IndexedDB
async function initDB(): Promise<IDBDatabase> {
  if (!isIndexedDBSupported()) {
    console.log('IndexedDB not supported, using localStorage');
    throw new Error('IndexedDB not supported');
  }

  return new Promise((resolve, reject) => {
    const request = indexedDB.open(DB_NAME, DB_VERSION);

    request.onerror = () => {
      console.error('Failed to open IndexedDB');
      reject(request.error);
    };

    request.onupgradeneeded = (event) => {
      const db = (event.target as IDBOpenDBRequest).result;
      
      // Create object stores if they don't exist
      if (!db.objectStoreNames.contains(PROJECTS_STORE)) {
        db.createObjectStore(PROJECTS_STORE, { keyPath: 'id' });
      }
      if (!db.objectStoreNames.contains(FILES_STORE)) {
        db.createObjectStore(FILES_STORE, { keyPath: 'id' });
      }
    };

    request.onsuccess = () => {
      const db = request.result;
      
      // Ensure object stores exist
      if (!db.objectStoreNames.contains(PROJECTS_STORE)) {
        const version = db.version + 1;
        db.close();
        const reopenRequest = indexedDB.open(DB_NAME, version);
        reopenRequest.onupgradeneeded = (event) => {
          const newDb = (event.target as IDBOpenDBRequest).result;
          newDb.createObjectStore(PROJECTS_STORE, { keyPath: 'id' });
        };
        reopenRequest.onsuccess = () => {
          resolve(reopenRequest.result);
        };
        reopenRequest.onerror = () => {
          console.error('Failed to create object store');
          reject(reopenRequest.error);
        };
        return;
      }
      
      if (!db.objectStoreNames.contains(FILES_STORE)) {
        const version = db.version + 1;
        db.close();
        const reopenRequest = indexedDB.open(DB_NAME, version);
        reopenRequest.onupgradeneeded = (event) => {
          const newDb = (event.target as IDBOpenDBRequest).result;
          newDb.createObjectStore(FILES_STORE, { keyPath: 'id' });
        };
        reopenRequest.onsuccess = () => {
          resolve(reopenRequest.result);
        };
        reopenRequest.onerror = () => {
          console.error('Failed to create object store');
          reject(reopenRequest.error);
        };
        return;
      }
      
      resolve(db);
    };
  });
}

async function storeFile(file: File): Promise<string> {
  const db = await initDB();
  const fileId = `file_${Date.now()}_${Math.random().toString(36).substring(7)}`;
  
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = async () => {
      try {
        const transaction = db.transaction([FILES_STORE], 'readwrite');
        const store = transaction.objectStore(FILES_STORE);
        
        const fileData = {
          id: fileId,
          data: reader.result,
          name: file.name,
          type: file.type,
          size: file.size,
          timestamp: Date.now()
        };

        const request = store.add(fileData);
        
        request.onsuccess = () => resolve(fileId);
        request.onerror = () => reject(request.error);
      } catch (error) {
        reject(error);
      }
    };
    reader.onerror = () => reject(reader.error);
    reader.readAsArrayBuffer(file);
  });
}

function generateId(): string {
  return `project_${Date.now()}_${Math.random().toString(36).substring(7)}`;
}

export async function submitProject(projectData: ProjectData): Promise<UploadResponse> {
  console.log('Starting project submission with data:', {
    name: projectData.name,
    email: projectData.email,
    filesCount: projectData.files.length,
    userId: projectData.userId
  });

  try {
    validateFiles(projectData.files);

    console.log('Processing files...');
    const filePromises = projectData.files.map(file => storeFile(file));
    const fileKeys = await Promise.all(filePromises);
    console.log('Files processed successfully:', fileKeys.length);

    // Create a temporary ID using email for non-logged-in users
    const tempUserId = projectData.userId || `temp_${projectData.email}`;

    const newProject: Project = {
      id: generateId(),
      userId: tempUserId,
      name: projectData.name,
      title: projectData.name,
      status: 'pending',
      date: new Date().toISOString(),
      type: projectData.type,
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString(),
      email: projectData.email,
      phone: projectData.phone,
      description: projectData.description,
      submissionDetails: {
        name: projectData.name,
        email: projectData.email,
        phone: projectData.phone,
        description: projectData.description,
        deliverables: projectData.deliverables
      },
      files: projectData.files.map((file, index) => ({
        name: file.name,
        size: formatFileSize(file.size),
        type: file.type,
        key: fileKeys[index],
        url: URL.createObjectURL(file)
      }))
    };

    console.log('Created new project:', { id: newProject.id, name: newProject.name });

    const db = await initDB();
    const transaction = db.transaction([PROJECTS_STORE], 'readwrite');
    const store = transaction.objectStore(PROJECTS_STORE);

    return new Promise((resolve, reject) => {
      const request = store.add(newProject);
      
      request.onsuccess = () => {
        resolve({
          success: true,
          reference: newProject.id,
          message: 'Projeto enviado com sucesso!',
          project: newProject
        });
      };
      
      request.onerror = () => {
        reject(new Error('Erro ao salvar projeto. Por favor, tente novamente.'));
      };
    });

  } catch (error) {
    console.error('Error in project submission:', error);
    throw new Error(
      error instanceof Error 
        ? error.message 
        : 'Erro ao enviar projeto. Por favor, tente novamente.'
    );
  }
}

// Get projects for a specific user
export async function getProjects(userId?: string, email?: string): Promise<Project[]> {
  try {
    const db = await initDB();
    const transaction = db.transaction([PROJECTS_STORE], 'readonly');
    const store = transaction.objectStore(PROJECTS_STORE);
    
    const allProjects = await new Promise<Project[]>((resolve, reject) => {
      const request = store.getAll();
      request.onsuccess = () => resolve(request.result);
      request.onerror = () => reject(request.error);
    });

    // If no userId or email provided, return all projects (admin view)
    if (!userId && !email) {
      return allProjects;
    }

    // Filter projects by userId or temporary email-based userId
    return allProjects.filter(project => 
      project.userId === userId || 
      project.userId === `temp_${email}` ||
      (project.email === email && project.userId?.startsWith('temp_'))
    );
  } catch (error) {
    console.error('Error fetching projects:', error);
    throw new Error('Erro ao carregar projetos');
  }
}

// Update project's userId when user registers
export async function updateProjectUserId(oldUserId: string, newUserId: string): Promise<void> {
  try {
    const db = await initDB();
    const transaction = db.transaction([PROJECTS_STORE], 'readwrite');
    const store = transaction.objectStore(PROJECTS_STORE);
    
    let request = await store.openCursor();
    let cursor = request as unknown as IDBCursorWithValue;
    
    while (cursor) {
      const project = cursor.value;
      if (project.userId === oldUserId) {
        project.userId = newUserId;
        await store.put(project);
      }
      cursor = await cursor.continue() as unknown as IDBCursorWithValue;
    }
  } catch (error) {
    console.error('Error updating project userId:', error);
    throw new Error('Erro ao atualizar projetos');
  }
}

export async function downloadFile(fileKey: string, fileName: string): Promise<void> {
  try {
    const db = await initDB();
    const transaction = db.transaction([FILES_STORE], 'readonly');
    const store = transaction.objectStore(FILES_STORE);
    
    return new Promise((resolve, reject) => {
      const request = store.get(fileKey);
      
      request.onsuccess = () => {
        const file = request.result;
        if (!file) {
          reject(new Error('Arquivo não encontrado'));
          return;
        }

        const blob = new Blob([file.data], { type: file.type });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
        resolve();
      };
      
      request.onerror = () => reject(request.error);
    });
  } catch (error) {
    console.error('Error downloading file:', error);
    throw error;
  }
}

// Cleanup function to remove old files
export async function cleanupOldFiles(daysOld = 30): Promise<void> {
  try {
    const db = await initDB();
    const transaction = db.transaction([FILES_STORE], 'readwrite');
    const store = transaction.objectStore(FILES_STORE);
    
    const cutoffDate = Date.now() - (daysOld * 24 * 60 * 60 * 1000);
    const request = store.openCursor();
    
    request.onsuccess = (event) => {
      const cursor = (event.target as IDBRequest).result;
      if (cursor) {
        const file = cursor.value;
        if (file.timestamp < cutoffDate) {
          store.delete(cursor.key);
        }
        cursor.continue();
      }
    };
  } catch (error) {
    console.error('Error cleaning up old files:', error);
  }
}

function isIndexedDBSupported(): boolean {
  return 'indexedDB' in window;
}