import { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Button } from '@/components/ui/button';
import { cn } from '@/lib/utils';
import { Menu, X, User, LogOut, Settings } from 'lucide-react';
import { LoginDialog } from './LoginDialog';
import { useAuth } from '@/contexts/AuthContext';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { AnimatePresence, motion } from 'framer-motion';

const Navbar = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isLoginOpen, setIsLoginOpen] = useState(false);
  const location = useLocation();
  const { isAuthenticated, user, logout } = useAuth();

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 20);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    console.log('Current user:', user); // Debug log
  }, [user]);

  const isActive = (path: string) => location.pathname === path;

  const navLinkClasses = (path: string) =>
    cn(
      'transition-all duration-200 relative py-1 text-lg',
      'after:absolute after:bottom-0 after:left-0 after:h-0.5 after:w-full',
      'after:origin-left after:scale-x-0 after:bg-white after:transition-transform',
      isActive(path)
        ? 'text-white font-medium after:scale-x-100'
        : 'text-white/90 hover:text-white hover:after:scale-x-100'
    );

  const isAdmin = user?.role === 'admin';

  return (
    <div className="fixed w-full z-50 px-6 pt-safe">
      <nav
        className={cn(
          'max-w-7xl mx-auto rounded-2xl relative',
          'bg-black/40 backdrop-blur-xl border border-white/10',
          'before:absolute before:inset-0 before:-z-10',
          'before:bg-gradient-to-b before:from-white/5 before:to-transparent',
          'before:rounded-2xl before:blur-2xl before:transform-gpu',
          'after:absolute after:inset-0 after:-z-20',
          'after:bg-white/5 after:rounded-[32px] after:blur-3xl after:transform-gpu',
          'shadow-[0_8px_32px_rgba(255,255,255,0.15)]',
          'shadow-white/10',
          'px-4 sm:px-8 py-4 sm:py-6'
        )}
      >
        <div className="flex justify-between items-center">
          <Link to="/" className="flex items-center">
            <div className="overflow-hidden flex items-center justify-center h-8 sm:h-10 md:h-12">
              <img
                src="/assets/logo.png"
                alt="Visio Partners"
                className="w-auto h-[200%] object-contain object-center max-w-none"
                loading="eager"
                style={{ background: 'transparent' }}
              />
            </div>
          </Link>

          {/* Desktop Menu */}
          <div className="hidden md:flex items-center space-x-8">
            <Link to="/about" className={navLinkClasses('/about')}>
              A Visio
            </Link>
            <Link to="/manifesto" className={navLinkClasses('/manifesto')}>
              Manifesto
            </Link>
            <Link to="/news" className={navLinkClasses('/news')}>
              Notícias
            </Link>
            <Link to="/faq" className={navLinkClasses('/faq')}>
              FAQ
            </Link>
            <Link to="/contact" className={navLinkClasses('/contact')}>
              Contato
            </Link>
          </div>

          <div className="hidden md:flex items-center space-x-4">
            <Button
              variant="default"
              size="default"
              className={cn(
                "bg-white text-black hover:bg-white/90 transition-all",
                "text-sm px-6 py-2 h-auto font-medium",
                "shadow-[0_8px_32px_rgba(255,255,255,0.25)]",
                "hover:shadow-[0_12px_48px_rgba(255,255,255,0.3)]"
              )}
              asChild
            >
              <Link to="/submit-project">Envie seu projeto</Link>
            </Button>

            {isAuthenticated ? (
              <DropdownMenu>
                <DropdownMenuTrigger asChild>
                  <Button 
                    variant="ghost" 
                    size="default"
                    className="border border-white/10 hover:bg-white/5 px-6 py-2 h-auto text-sm"
                  >
                    <User className="mr-2 h-4 w-4" />
                    Conta
                  </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent className="w-48 bg-black/90 backdrop-blur-xl border-white/10">
                  <DropdownMenuItem asChild>
                    <Link to="/member" className="cursor-pointer flex items-center">
                      <User className="mr-2 h-4 w-4" />
                      Perfil
                    </Link>
                  </DropdownMenuItem>
                  {isAdmin && (
                    <DropdownMenuItem asChild>
                      <Link to="/admin" className="cursor-pointer flex items-center">
                        <Settings className="mr-2 h-4 w-4" />
                        Painel Administrativo
                      </Link>
                    </DropdownMenuItem>
                  )}
                  <DropdownMenuItem 
                    className="text-red-400 cursor-pointer"
                    onClick={logout}
                  >
                    <LogOut className="mr-2 h-4 w-4" />
                    Sair
                  </DropdownMenuItem>
                </DropdownMenuContent>
              </DropdownMenu>
            ) : (
              <Button 
                variant="ghost" 
                className="border border-white/10 hover:bg-white/5"
                onClick={() => setIsLoginOpen(true)}
              >
                Entrar
              </Button>
            )}
          </div>

          {/* Mobile Menu Button */}
          <div className="md:hidden">
            <Button
              variant="ghost"
              size="icon"
              onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
              className="text-white"
            >
              {isMobileMenuOpen ? <X /> : <Menu />}
            </Button>
          </div>
        </div>

        {/* Mobile Menu */}
        {isMobileMenuOpen && (
          <div className="md:hidden mt-6 pb-4">
            <nav className="flex flex-col space-y-3">
              <Link to="/about" className="text-white/90 hover:text-white">
                A Visio
              </Link>
              <Link to="/manifesto" className="text-white/90 hover:text-white">
                Manifesto
              </Link>
              <Link to="/news" className="text-white/90 hover:text-white">
                Notícias
              </Link>
              <Link to="/faq" className="text-white/90 hover:text-white">
                FAQ
              </Link>
              <Link to="/contact" className="text-white/90 hover:text-white">
                Contato
              </Link>
              <Link to="/submit-project" className="text-white font-medium">
                Envie seu projeto
              </Link>
              {isAuthenticated ? (
                <>
                  <Link to="/member" className="text-white/90 hover:text-white flex items-center gap-2">
                    <User className="h-4 w-4" />
                    Perfil
                  </Link>
                  {isAdmin && (
                    <Link to="/admin" className="text-white/90 hover:text-white flex items-center gap-2">
                      <Settings className="h-4 w-4" />
                      Painel Administrativo
                    </Link>
                  )}
                  <button
                    onClick={logout}
                    className="text-red-400 hover:text-red-300 text-left flex items-center gap-2"
                  >
                    <LogOut className="h-4 w-4" />
                    Sair
                  </button>
                </>
              ) : (
                <button
                  onClick={() => setIsLoginOpen(true)}
                  className="text-white/90 hover:text-white text-left"
                >
                  Entrar
                </button>
              )}
            </nav>
          </div>
        )}
      </nav>

      <LoginDialog open={isLoginOpen} onOpenChange={setIsLoginOpen} />
    </div>
  );
};

export default Navbar;