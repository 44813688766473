import { useState, useEffect } from 'react';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { toast } from 'sonner';
import { Upload, Link as LinkIcon, Loader2 } from 'lucide-react';

interface ImageEditorProps {
  currentImage?: string;
  onSelect: (imageUrl: string) => void;
}

export function ImageEditor({ currentImage, onSelect }: ImageEditorProps) {
  const [imageUrl, setImageUrl] = useState(currentImage || '');
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [previewUrl, setPreviewUrl] = useState<string | null>(null);
  const [isImageValid, setIsImageValid] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isImageLoading, setIsImageLoading] = useState(false);

  // Reset states when currentImage changes
  useEffect(() => {
    if (currentImage) {
      setImageUrl(currentImage);
      setPreviewUrl(null);
      setSelectedFile(null);
      validateImage(currentImage);
    }
  }, [currentImage]);

  const validateImage = async (url: string) => {
    setIsImageLoading(true);
    return new Promise((resolve) => {
      const img = new Image();
      img.onload = () => {
        setIsImageValid(true);
        setIsImageLoading(false);
        resolve(true);
      };
      img.onerror = () => {
        setIsImageValid(false);
        setIsImageLoading(false);
        resolve(false);
      };
      img.src = url;
    });
  };

  const handleUrlSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!imageUrl) {
      toast.error('Por favor, insira uma URL válida');
      return;
    }

    setIsLoading(true);
    try {
      const isValid = await validateImage(imageUrl);
      if (!isValid) {
        toast.error('A URL fornecida não é uma imagem válida');
        return;
      }
      onSelect(imageUrl);
      toast.success('Imagem selecionada com sucesso');
    } catch (error) {
      toast.error('Erro ao validar a imagem');
    } finally {
      setIsLoading(false);
    }
  };

  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      try {
        // Check file type
        if (!file.type.startsWith('image/')) {
          toast.error('Por favor, selecione um arquivo de imagem válido');
          return;
        }

        // Check file size (5MB limit)
        if (file.size > 5 * 1024 * 1024) {
          toast.error('O arquivo deve ter menos de 5MB');
          return;
        }

        setIsLoading(true);

        // Create object URL instead of DataURL for better performance
        const objectUrl = URL.createObjectURL(file);
        setSelectedFile(file);
        setPreviewUrl(objectUrl);
        
        // Validate the image
        const isValid = await validateImage(objectUrl);
        if (!isValid) {
          toast.error('O arquivo selecionado não é uma imagem válida');
          URL.revokeObjectURL(objectUrl);
          setSelectedFile(null);
          setPreviewUrl(null);
          return;
        }
      } catch (error) {
        toast.error('Erro ao processar a imagem');
        console.error('Error processing image:', error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleFileSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!selectedFile || !previewUrl) {
      toast.error('Por favor, selecione um arquivo');
      return;
    }

    setIsLoading(true);
    try {
      // Validate one last time before submitting
      const isValid = await validateImage(previewUrl);
      if (!isValid) {
        toast.error('A imagem selecionada não é válida');
        return;
      }

      onSelect(previewUrl);
      toast.success('Imagem enviada com sucesso');
    } catch (error) {
      toast.error('Erro ao fazer upload da imagem');
      console.error('Error uploading image:', error);
    } finally {
      setIsLoading(false);
    }
  };

  // Cleanup object URLs when component unmounts or when preview changes
  useEffect(() => {
    return () => {
      if (previewUrl && previewUrl.startsWith('blob:')) {
        URL.revokeObjectURL(previewUrl);
      }
    };
  }, [previewUrl]);

  return (
    <div className="p-4">
      <Tabs defaultValue="url">
        <TabsList className="grid w-full grid-cols-2">
          <TabsTrigger value="url">
            <LinkIcon className="h-4 w-4 mr-2" />
            URL
          </TabsTrigger>
          <TabsTrigger value="upload">
            <Upload className="h-4 w-4 mr-2" />
            Upload
          </TabsTrigger>
        </TabsList>

        <TabsContent value="url" className="space-y-4">
          <form onSubmit={handleUrlSubmit} className="space-y-4">
            <div>
              <Label>URL da Imagem</Label>
              <Input
                type="url"
                value={imageUrl}
                onChange={(e) => {
                  setImageUrl(e.target.value);
                  setIsImageValid(true); // Reset validation on change
                }}
                placeholder="https://exemplo.com/imagem.jpg"
                className="bg-white/5 border-white/10"
                disabled={isLoading}
              />
            </div>

            {imageUrl && (
              <div className="relative aspect-video rounded-lg overflow-hidden bg-black/20">
                {isImageLoading ? (
                  <div className="absolute inset-0 flex items-center justify-center">
                    <Loader2 className="h-6 w-6 animate-spin text-white/70" />
                  </div>
                ) : (
                  <>
                    <img
                      src={imageUrl}
                      alt="Preview"
                      className="w-full h-full object-cover"
                      onError={() => setIsImageValid(false)}
                    />
                    {!isImageValid && (
                      <div className="absolute inset-0 flex items-center justify-center bg-black/50 text-white">
                        Imagem inválida
                      </div>
                    )}
                  </>
                )}
              </div>
            )}

            <Button 
              type="submit" 
              className="w-full" 
              disabled={!isImageValid || isLoading}
            >
              {isLoading ? (
                <>
                  <Loader2 className="h-4 w-4 mr-2 animate-spin" />
                  Carregando...
                </>
              ) : (
                'Selecionar'
              )}
            </Button>
          </form>
        </TabsContent>

        <TabsContent value="upload" className="space-y-4">
          <form onSubmit={handleFileSubmit} className="space-y-4">
            <div>
              <Label>Selecionar Arquivo</Label>
              <Input
                type="file"
                onChange={handleFileChange}
                accept="image/*"
                className="bg-white/5 border-white/10"
                disabled={isLoading}
              />
              <p className="text-sm text-white/60 mt-1">
                Tamanho máximo: 5MB. Formatos: JPG, PNG, GIF
              </p>
            </div>

            {previewUrl && (
              <div className="relative aspect-video rounded-lg overflow-hidden bg-black/20">
                {isImageLoading ? (
                  <div className="absolute inset-0 flex items-center justify-center">
                    <Loader2 className="h-6 w-6 animate-spin text-white/70" />
                  </div>
                ) : (
                  <>
                    <img
                      src={previewUrl}
                      alt="Preview"
                      className="w-full h-full object-cover"
                      onError={() => setIsImageValid(false)}
                    />
                    {!isImageValid && (
                      <div className="absolute inset-0 flex items-center justify-center bg-black/50 text-white">
                        Imagem inválida
                      </div>
                    )}
                  </>
                )}
              </div>
            )}

            <Button 
              type="submit" 
              className="w-full" 
              disabled={!selectedFile || !isImageValid || isLoading}
            >
              {isLoading ? (
                <>
                  <Loader2 className="h-4 w-4 mr-2 animate-spin" />
                  Carregando...
                </>
              ) : (
                'Upload'
              )}
            </Button>
          </form>
        </TabsContent>
      </Tabs>
    </div>
  );
}
