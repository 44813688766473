import { useState } from 'react';
import { X } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';

const WhatsAppButton = () => {
  const [isOpen, setIsOpen] = useState(false);

  const handleClick = () => {
    if (isOpen) {
      setIsOpen(false);
    } else {
      window.open('https://wa.me/5531993984114', '_blank');
    }
  };

  return (
    <div className="fixed bottom-6 right-6 z-50" data-whatsapp-float>
      <motion.div
        initial={{ scale: 0 }}
        animate={{ scale: 1 }}
        className="relative"
      >
        <motion.button
          onClick={handleClick}
          className="bg-[#25D366] hover:bg-[#20BA5C] p-4 rounded-full shadow-lg transition-all duration-300 flex items-center justify-center"
          style={{
            boxShadow: '0 4px 20px rgba(37, 211, 102, 0.3)',
          }}
          whileHover={{ scale: 1.1 }}
          animate={{
            y: [0, -10, 0],
          }}
          transition={{
            duration: 2,
            repeat: Infinity,
            ease: "easeInOut"
          }}
        >
          <img 
            src="https://upload.wikimedia.org/wikipedia/commons/5/5e/WhatsApp_icon.png?20200503174721"
            alt="WhatsApp"
            className="h-6 w-6"
          />
        </motion.button>

        <AnimatePresence>
          {isOpen && (
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 20 }}
              className="absolute bottom-full right-0 mb-4 bg-black/40 backdrop-blur-xl border border-white/10 rounded-lg shadow-[0_8px_32px_rgba(255,255,255,0.15)] p-4 w-72"
            >
              <button
                onClick={() => setIsOpen(false)}
                className="absolute top-2 right-2 text-white/50 hover:text-white"
              >
                <X className="h-4 w-4" />
              </button>
              <p className="text-white mb-2">Olá! Como podemos ajudar?</p>
              <p className="text-sm text-white/70">
                Clique para iniciar uma conversa no WhatsApp.
              </p>
            </motion.div>
          )}
        </AnimatePresence>
      </motion.div>
    </div>
  );
};

export default WhatsAppButton;