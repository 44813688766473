import { createContext, useContext, ReactNode } from 'react';
import { create } from 'zustand';
import { persist, createJSONStorage } from 'zustand/middleware';
import { login } from '@/lib/api';
import { initDB } from '@/lib/db'; // Import initDB function

interface User {
  uid: string;
  id: string;
  name: string;
  email: string | null;
  role?: 'user' | 'admin';
  phone?: string;
  company?: string;
  createdAt: string;
}

interface AuthState {
  user: User | null;
  isAuthenticated: boolean;
  login: (email: string, password: string) => Promise<User>;
  logout: () => void;
  updateUser: (user: User) => void;
  isAdmin: () => boolean;
}

const useAuthStore = create<AuthState>()(
  persist(
    (set, get) => ({
      user: null,
      isAuthenticated: false,
      login: async (email: string, password: string) => {
        try {
          console.log('AuthContext: Starting login for', email);
          
          // Clear any existing token before attempting login
          localStorage.removeItem('token');
          console.log('AuthContext: Cleared existing token');
          
          const response = await login(email, password);
          console.log('AuthContext: Received login response', {
            hasToken: !!response?.token,
            hasUser: !!response?.user,
            userData: response?.user ? {
              id: response.user.id,
              role: response.user.role,
              email: response.user.email
            } : null
          });
          
          if (!response?.token || !response?.user) {
            throw new Error('Resposta inválida do servidor');
          }

          // Store the token
          localStorage.setItem('token', response.token);
          console.log('AuthContext: Stored new token');

          // Process user data
          const userData = {
            ...response.user,
            id: response.user.id || response.user.sub || response.user._id,
            role: response.user.role || 'user',
            email: email // Ensure email is set
          };

          // Check for any pending projects associated with this email
          const db = await initDB();
          const transaction = db.transaction(['projects'], 'readwrite');
          const store = transaction.objectStore('projects');
          
          const tempUserId = `temp_${email}`;
          const cursor = await store.openCursor();
          
          while (cursor) {
            const project = cursor.value;
            if (project.userId === tempUserId) {
              project.userId = userData.id;
              await store.put(project);
            }
            await cursor.continue();
          }

          console.log('AuthContext: Updated any pending projects');

          // Update auth state
          set({
            user: userData,
            isAuthenticated: true
          });

          console.log('AuthContext: Updated auth state');
          return userData;
        } catch (error: any) {
          console.error('AuthContext: Login error', {
            message: error.message,
            response: error.response?.data
          });
          
          // Clear any invalid token
          localStorage.removeItem('token');
          console.log('AuthContext: Cleared invalid token');
          
          // Reset auth state
          set({
            user: null,
            isAuthenticated: false
          });
          console.log('AuthContext: Reset auth state');
          
          throw error; // Re-throw the original error
        }
      },
      logout: () => {
        localStorage.removeItem('token');
        set({ user: null, isAuthenticated: false });
      },
      updateUser: (user: User) => {
        set({ user, isAuthenticated: true });
      },
      isAdmin: () => {
        const state = get();
        return state.user?.role === 'admin';
      }
    }),
    {
      name: 'auth-storage',
      storage: createJSONStorage(() => localStorage),
      version: 1,
    }
  )
);

const AuthContext = createContext<AuthState | null>(null);

export function AuthProvider({ children }: { children: ReactNode }) {
  return (
    <AuthContext.Provider value={useAuthStore()}>
      {children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
}

export { useAuthStore };