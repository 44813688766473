import { create } from 'zustand';
import { persist, createJSONStorage } from 'zustand/middleware';
import { produce } from 'immer';
import type { ContentState, ContentSection, NewsItem, Project, LegalContent } from '@/types/content';
import { openDB } from '@/lib/db';

const defaultImages = {
  home: '/assets/home.jpg',
  about: '/assets/about.JPEG',
  manifest: '/assets/manifest.jpg',
  faq: '/assets/faq.jpg',
  contact: '/assets/contact.jpg',
  banner: '/assets/banner-bg.jpg'
};

const initialContent: ContentSection = {
  title: '',
  subtitle: '',
  description: '',
  bannerImage: defaultImages.banner,
  heroImage: '',
  faqs: [],
};

const defaultContent = {
  home: {
    ...initialContent,
    mainTitle: 'Orçamentação e Gestão de Custo',
    subtitle: 'A Visio está revolucionando o processo de orçamento de obras com sua inteligência personalizada de custos. Integramos a experiência de engenheiros e especialistas à tecnologia para oferecer uma estrutura orçamentária completa e de fácil entendimento para os nossos clientes.',
    heroImage: defaultImages.home,
    bannerImage: defaultImages.banner,
    statsTitle: 'Nossos Números',
    stats: [
      {
        value: '248',
        label: 'Projetos Orçados',
        icon: 'building'
      },
      {
        value: '98%',
        label: 'Clientes Satisfeitos',
        icon: 'users'
      },
      {
        value: '15+',
        label: 'Anos de Experiência',
        icon: 'clock'
      }
    ]
  },
  about: {
    ...initialContent,
    title: 'Nós empoderamos os nossos clientes',
    subtitle: 'A Visio é uma construtech que potencializa os resultados dos seus projetos de construção com inovação e tecnologia. Nossa missão é empoderar donos de obras, arquitetos e construtoras com dados reais de mercado, aplicados à uma estrutura cuidadosamente elaborada ao longo dos últimos anos, oferecendo as melhores condições para uma tomada de decisão mais assertiva e embasada.',
    heroImage: defaultImages.about,
    bannerImage: defaultImages.banner,
    missionTitle: 'Nossa Missão',
    missionText: 'Empoderar donos de obras, arquitetos e construtoras com dados reais de mercado.',
    visionTitle: 'Nossa Visão',
    visionText: 'Ser referência em soluções inovadoras para o setor da construção civil.',
    valuesTitle: 'Nossos Valores',
    valuesText: 'Transparência, inovação e excelência em tudo que fazemos.'
  },
  manifesto: {
    ...initialContent,
    title: 'Nosso Propósito',
    subtitle: 'Somos dedicados a revolucionar a indústria de construção civil por meio de inovação e tecnologia. Ao se tornar um cliente da Visio, você se torna o protagonista da sua obra, aumentando seu acesso às informações de custos, garantindo mais previsibilidade e transparência durante a execução.',
    heroImage: defaultImages.manifest,
    bannerImage: defaultImages.banner,
    purposeTitle: 'Nosso Propósito',
    purposeText: 'Revolucionar a indústria da construção civil através da tecnologia.',
    innovationTitle: 'Inovação Constante',
    innovationText: 'Buscamos sempre as melhores soluções tecnológicas.',
    futureTitle: 'Futuro da Construção',
    futureText: 'Construindo um futuro mais eficiente e transparente.'
  },
  contact: {
    ...initialContent,
    title: 'Olá, Partner! 👋',
    subtitle: 'Fale conosco por algum dos canais abaixo.',
    email: 'partners.visio@gmail.com',
    phone: '(31) 99398-4114',
    address: 'Belo Horizonte, MG',
    heroImage: defaultImages.contact,
    bannerImage: defaultImages.banner
  },
  faq: {
    ...initialContent,
    title: 'Perguntas Frequentes',
    subtitle: 'Encontre respostas para as dúvidas mais comuns sobre nossos serviços e soluções.',
    heroImage: defaultImages.faq,
    bannerImage: defaultImages.banner,
    faqs: [
      {
        id: '1',
        question: 'Como a Visio utiliza tecnologia para otimizar orçamentos?',
        answer: 'A Visio integra a experiência de engenheiros e especialistas com tecnologia avançada para criar uma estrutura orçamentária personalizada. Utilizamos inteligência artificial e dados reais de mercado para oferecer orçamentos precisos e detalhados, permitindo uma tomada de decisão mais assertiva em seus projetos de construção.'
      },
      {
        id: '2',
        question: 'Quais são os benefícios de trabalhar com a Visio Partners?',
        answer: 'Ao trabalhar com a Visio, você se beneficia de nossa experiência de mais de 15 anos no mercado, com mais de 248 projetos orçados e 98% de satisfação dos clientes. Nossa abordagem combina dados reais de mercado com tecnologia avançada, oferecendo maior previsibilidade e transparência durante a execução do seu projeto.'
      },
      {
        id: '3',
        question: 'Como funciona o processo de orçamentação da Visio?',
        answer: 'Nosso processo é estruturado e transparente: você nos envia seu projeto, nossa equipe especializada analisa utilizando nossa tecnologia proprietária que integra dados reais de mercado, e retornamos com um orçamento detalhado e de fácil entendimento. Todo o processo é feito com foco em empoderar você com informações precisas para uma melhor tomada de decisão.'
      }
    ]
  },
  news: [
    {
      id: '1',
      title: 'Como a Inteligência Artificial está revolucionando a construção civil',
      subtitle: 'Descubra as últimas inovações',
      description: 'Explore como a IA está transformando o setor da construção civil, desde o planejamento até a execução de projetos.',
      content: 'A inteligência artificial está transformando rapidamente o setor da construção civil...',
      author: 'Equipe Visio',
      date: '2024-01-15',
      image: 'https://blog.saninternet.com/wp-content/uploads/2024/04/inteligencia-artificial.png',
      imageUrl: 'https://blog.saninternet.com/wp-content/uploads/2024/04/inteligencia-artificial.png',
      category: 'Tecnologia',
      readTime: '5 min'
    },
    {
      id: '2',
      title: 'Sustentabilidade na Construção: Tendências para 2024',
      subtitle: 'O futuro da construção sustentável',
      description: 'Conheça as principais tendências em construção sustentável e como elas estão moldando o futuro do setor.',
      content: 'A construção sustentável está se tornando cada vez mais importante...',
      author: 'Equipe Visio',
      date: '2024-01-20',
      image: 'https://www.3tc.com.br/wp-content/uploads/2020/02/sustentabilidade-na-construcao-civil-guia-completo-sobre-o-assunto.jpeg',
      imageUrl: 'https://www.3tc.com.br/wp-content/uploads/2020/02/sustentabilidade-na-construcao-civil-guia-completo-sobre-o-assunto.jpeg',
      category: 'Sustentabilidade',
      readTime: '4 min'
    },
    {
      id: '3',
      title: 'Gestão de Custos: Como otimizar seu orçamento',
      subtitle: 'Maximize seus recursos',
      description: 'Aprenda estratégias eficientes para gerenciar custos em projetos de construção.',
      content: 'A gestão eficiente de custos é fundamental para o sucesso de qualquer projeto...',
      author: 'Equipe Visio',
      date: '2024-01-25',
      image: 'https://media.treasy.com.br/media/2015/03/img13-1.jpg',
      imageUrl: 'https://media.treasy.com.br/media/2015/03/img13-1.jpg',
      category: 'Gestão',
      readTime: '6 min'
    }
  ],
  projects: [],
  terms: { content: '' },
  privacy: { content: '' },
  dataPolicy: { content: '' },
};

const INITIAL_STATE: ContentState = {
  home: defaultContent.home,
  about: defaultContent.about,
  manifesto: defaultContent.manifesto,
  contact: defaultContent.contact,
  faq: defaultContent.faq,
  news: defaultContent.news,
  projects: defaultContent.projects,
  terms: defaultContent.terms,
  privacy: defaultContent.privacy,
  dataPolicy: defaultContent.dataPolicy,
  updateHome: () => {},
  updateAbout: () => {},
  updateManifesto: () => {},
  updateContact: () => {},
  updateNews: () => {},
  updateProjects: () => {},
  updateTerms: () => {},
  updatePrivacy: () => {},
  updateDataPolicy: () => {},
  updateFaq: () => {}
};

const useContentStore = create<ContentState>()(
  persist(
    (set) => ({
      ...defaultContent,
      updateHome: (content: ContentSection) =>
        set(
          produce((state) => {
            state.home = { ...state.home, ...content };
          })
        ),
      updateAbout: (content: ContentSection) =>
        set(
          produce((state) => {
            state.about = { ...state.about, ...content };
          })
        ),
      updateManifesto: (content: ContentSection) =>
        set(
          produce((state) => {
            state.manifesto = { ...state.manifesto, ...content };
          })
        ),
      updateContact: (content: ContentSection) =>
        set(
          produce((state) => {
            state.contact = { ...state.contact, ...content };
          })
        ),
      updateFaq: (content: ContentSection) =>
        set(
          produce((state) => {
            state.faq = { ...state.faq, ...content };
          })
        ),
      updateNews: (content: NewsItem[]) =>
        set(
          produce((state) => {
            state.news = content;
          })
        ),
      addNewsItem: (item: NewsItem) =>
        set(
          produce((state) => {
            state.news.push(item);
          })
        ),
      updateNewsItem: (id: string, item: Partial<NewsItem>) =>
        set(
          produce((state) => {
            const index = state.news.findIndex((n: NewsItem) => n.id === id);
            if (index !== -1) {
              state.news[index] = { ...state.news[index], ...item };
            }
          })
        ),
      deleteNewsItem: (id: string) =>
        set(
          produce((state) => {
            state.news = state.news.filter((n: NewsItem) => n.id !== id);
          })
        ),
      updateProjects: (projects: Project[]) =>
        set(
          produce((state) => {
            state.projects = projects;
          })
        ),
      addProject: (project: Project) =>
        set(
          produce((state) => {
            state.projects.push(project);
          })
        ),
      updateProject: (id: string, project: Partial<Project>) =>
        set(
          produce((state) => {
            const index = state.projects.findIndex((p: Project) => p.id === id);
            if (index !== -1) {
              state.projects[index] = { ...state.projects[index], ...project };
            }
          })
        ),
      deleteProject: (id: string) =>
        set(
          produce((state) => {
            state.projects = state.projects.filter((p: Project) => p.id !== id);
          })
        ),
      updateTerms: (content: LegalContent) =>
        set(
          produce((state) => {
            state.terms = { ...state.terms, ...content };
          })
        ),
      updatePrivacy: (content: LegalContent) =>
        set(
          produce((state) => {
            state.privacy = { ...state.privacy, ...content };
          })
        ),
      updateDataPolicy: (content: LegalContent) =>
        set(
          produce((state) => {
            state.dataPolicy = { ...state.dataPolicy, ...content };
          })
        ),
    }),
    {
      name: 'visio-content',
      storage: createJSONStorage(() => {
        if (typeof window !== 'undefined') {
          if (process.env.NODE_ENV === 'production') {
            return {
              getItem: async (name) => {
                const db = await openDB('visio-content', 1);
                const tx = db.transaction('content', 'readonly');
                const store = tx.objectStore('content');
                const value = await store.get(name);
                return value || null;
              },
              setItem: async (name, value) => {
                const db = await openDB('visio-content', 1);
                const tx = db.transaction('content', 'readwrite');
                const store = tx.objectStore('content');
                await store.put(value, name);
              },
              removeItem: async (name) => {
                const db = await openDB('visio-content', 1);
                const tx = db.transaction('content', 'readwrite');
                const store = tx.objectStore('content');
                await store.delete(name);
              },
            };
          }
          return localStorage;
        }
        return {
          getItem: () => null,
          setItem: () => {},
          removeItem: () => {},
        };
      }),
    }
  )
);

export { useContentStore };
export default useContentStore;