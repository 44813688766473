import { Card } from '@/components/ui/card';
import { motion } from 'framer-motion';

const DataPolicy = () => {
  return (
    <div className="min-h-screen pt-40 pb-32">
      <div className="max-w-7xl mx-auto px-8">
        <motion.h1 
          className="text-5xl font-bold mb-12 text-center"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
        >
          Política de Dados
        </motion.h1>

        <Card className="p-12 bg-black/40 backdrop-blur-xl border-white/10">
          <div className="prose prose-invert max-w-none prose-lg">
            <h2>1. Coleta de Dados</h2>
            <p>A Visio Partners coleta e utiliza alguns dados pessoais que pertencem àqueles que utilizam nosso site. Ao fazê-lo, agimos na qualidade de controlador desses dados e estamos sujeitos às disposições da Lei Federal n. 13.709/2018 (Lei Geral de Proteção de Dados Pessoais – LGPD).</p>

            <h2>2. Dados Pessoais</h2>
            <p>Nós cuidamos da proteção de seus dados pessoais e, por isso, disponibilizamos esta política de privacidade, que contém informações importantes sobre:</p>
            <ul>
              <li>Quem deve utilizar nosso site</li>
              <li>Quais dados coletamos e o que fazemos com eles;</li>
              <li>Seus direitos em relação aos seus dados pessoais; e</li>
              <li>Como entrar em contato conosco.</li>
            </ul>

            <h2>3. Dados sensíveis</h2>
            <p>Não serão coletados dados sensíveis de nossos usuários, assim entendidos aqueles definidos nos arts. 11 e seguintes da LGPD. Assim, não haverá coleta de dados sobre origem racial ou étnica, convicção religiosa, opinião política, filiação a sindicato ou a organização de caráter religioso, filosófico ou político, dado referente à saúde ou à vida sexual, dado genético ou biométrico, quando vinculado a uma pessoa natural.</p>

            <h2>4. Cookies</h2>
            <p>Cookies são pequenos arquivos de texto baixados automaticamente em seu dispositivo quando você acessa e navega por um site. Eles servem, basicamente, para seja possível identificar dispositivos, atividades e preferências de usuários.</p>

            <h2>5. Alterações</h2>
            <p>A presente versão desta Política de Privacidade foi atualizada pela última vez em: 01/03/2024. Reservamo-nos o direito de modificar, a qualquer momento, as presentes normas, especialmente para adaptá-las às eventuais alterações feitas em nosso site, seja pela disponibilização de novas funcionalidades, seja pela supressão ou modificação daquelas já existentes.</p>
          </div>
        </Card>
      </div>
    </div>
  );
};

export default DataPolicy;